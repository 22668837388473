import { Divider } from "@mui/material";
import React, { Fragment } from "react";
import { SubHeading } from "../Typography/Typo";
import ExperienceCard from "./ExperienceCard";
import { SectionInnerContainer } from "./UserProfile";
import { SectionContainer } from "./UserProfileHeader";

function WorkSection({ isPublic, profileData }) {
  return (
    <SectionContainer>
      <SectionInnerContainer>
        <SubHeading primary>Experience</SubHeading>{" "}
        {profileData?.workData &&
          profileData?.workData?.map((item, key) => {
            return (
              <Fragment key={key}>
                <ExperienceCard
                  fieldData={item}
                  title={item?.data?.position?.data}
                  logoImage={item?.data?.imageLogo?.data}
                  status={0}
                  isPublic={isPublic}
                  startDate={item?.data?.startDate?.data}
                  institution={item?.data?.company?.data}
                  endDate={item?.data?.endDate?.data}
                  description={item?.data?.description?.data}
                />
                {profileData?.workData?.length > 1 &&
                  profileData?.workData?.length - 1 != key && (
                    <Divider
                      style={{
                        background: "#5e5e5e11",
                        width: "100%",
                      }}
                    />
                  )}
              </Fragment>
            );
          })}
      </SectionInnerContainer>
    </SectionContainer>
  );
}

export default WorkSection;
