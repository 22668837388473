import React, { Fragment } from "react";
import styled from "styled-components";
import { Text } from "../../Typography/Typo";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";

export const TextField = (props) => {
  return (
    <Container span={Boolean(props.span)} style={props.style}>
      <InputContainer custom={props.custom}>
        <Label style={{ paddingBottom: "5px" }} id={props.id}>
          {props.label}
        </Label>
      </InputContainer>
      <TextInputContainer>
        <Fragment>{props.startIcon}</Fragment>
        <Input
          form={props.form}
          disabled={props.disabled}
          type={props.type}
          placeholder={props.placeholder}
          required={props.required}
          onChange={
            props.onChange && (({ target: { value } }) => props.onChange(value))
          }
          value={props.value}
          name={props.name}
          id={props.id}
          error={props.error}
          control={props.control}
          onBlur={props.onBlur}
          onKeyPress={props.onKeyPress}
          maxLength={props.maxLength}
        />
        <Fragment>{props.icon}</Fragment>
      </TextInputContainer>
      <FlexContainer>
        <Text lighter small color={props.color}>
          {props.helper}
        </Text>
      </FlexContainer>
    </Container>
  );
};

const TextInputContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  background-color: #c3d0e1;
  border-radius: 5px;
  align-items: center;
`;

const Input = styled.input`
  border: none;
  width: 100%;
  padding: 0rem 0.5rem;
  outline: none;
  color: #080036;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  @media (max-width: 1368px) {
    font-size: 14px;
  }
`;

export const Label = styled.label`
  color: #080036;
  font-weight: 500;
`;

export const Container = styled.div`
  gap: 0.3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.custom ? "0.2rem" : "0")}; ;
`;
