import { Avatar, AvatarGroup } from "@mui/material";
import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { CustomButton } from "../fields/Button/CustomButton";
import { Text } from "../Typography/Typo";
import VerificationAccordion from "./VerificationAccordion";
import VerificationStepper from "./VerificationStepper";

export const VerificationStatusView = ({ verification, setShowAction }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = () => {
    setShowAction(false);
  };

  return (
    <Fragment>
      <OverLayContainer onClick={handleClose} />
      <Container>
        {verification?.fieldData?.verifiedBy?.length > 1 && (
          <FlexContainer>
            <Text primary>Requested To</Text>
            {/* <AvatarGroup total={verification?.fieldData?.verifiedBy?.length}>
            {verification?.fieldData?.verifiedBy.map((item, key) => (
              <Avatar
                sx={{ width: 24, height: 24 }}
                key={key}
                alt={item?.receiverAlias}
                src={item?.receiverImage}
              />
            ))}
          </AvatarGroup> */}
          </FlexContainer>
        )}
        {verification?.fieldData?.verifiedBy?.length === 0 && (
          <Text primary>There are no available verification information</Text>
        )}
        <OverFlowContainer>
          {verification?.fieldData?.verifiedBy.map((item, key) => {
            if (verification?.fieldData?.verifiedBy?.length > 1) {
              return (
                <VerificationAccordion
                  key={key}
                  item={item}
                  verification={verification}
                  handleChange={handleChange}
                  expanded={expanded}
                  index={key}
                />
              );
            } else {
              return (
                <VerificationStepper
                  key={key}
                  item={item}
                  verification={verification}
                />
              );
            }
          })}
        </OverFlowContainer>
      </Container>
    </Fragment>
  );
};

export default VerificationStatusView;

export const Container = styled.div`
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 40%;
  z-index: 999;
  height: auto;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
    padding: 1.5rem;
  }
  @media (max-width: 1368px) {
    width: 80%;
  }
`;

const OverFlowContainer = styled.div`
  max-height: 30rem;
  overflow-y: auto;
  padding: 0rem 1rem 2rem 1rem;
  width: 93%;
`;

const FlexContainer = styled.div`
  width: 93%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

const OverLayContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;
