import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { Text } from "../Typography/Typo";
import moment from "moment";
import { urlToHash } from "../../../services/ipfs";

export const ViewVerifiedDetails = ({ setShowAction, verifiedData }) => {
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const [hover, setHover] = useState(false);

  return (
    <Fragment>
      <OverLayContainer
        onClick={() => {
          if (!loading && !hover) {
            setShowAction(false);
          }
        }}
      >
        <Container
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <FlexContainer addPadding>
            <GridContainer>
              <Text grid lighter primary>
                Verified By
              </Text>{" "}
              :<Span>{verifiedData?.verifiedBy[0].senderAlias}</Span>
              <Text grid lighter primary>
                Verified PublicKey
              </Text>
              :<Span>{verifiedData?.verifiedBy[0].senderPublicKey}</Span>
              <Text grid lighter primary>
                Verified Date
              </Text>
              :
              <Span>
                {moment(verifiedData?.verifiedBy[0].verifiedAt).format("LLL")}
              </Span>
            </GridContainer>
            <LargeContainer>
              <Icon src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(verifiedData?.verifiedBy[0].senderImage)}`} alt="logo" />
            </LargeContainer>
          </FlexContainer>
        </Container>
      </OverLayContainer>
    </Fragment>
  );
};

const FlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem, 0rem;
`;

const GridContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1.3fr 0.01fr 4fr;
  @media (max-width: 768px) {
  }
`;

export const SmallText = styled.p`
  font-size: 0.7rem;
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200%;
  width: 100%;
  top: 100%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 45rem;
  z-index: 999;
  height: 10rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Span = styled.span`
  font-weight: 500;
  max-width: 400px;
  word-break: break-word;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};

  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
    font-size: 14px;
  }
`;

const LargeContainer = styled.div`
  position: relative;
  width: 6rem;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  height: 6rem;
  background: #edf1f4;
  border-radius: 50%;
`;

const Icon = styled.img`
  width: 100%;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
`;
