import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton } from "@mui/material";

import { urlToHash, GetIPFSData } from "../../../services/ipfs";
import { SubHeading, Text } from "../Typography/Typo";
import CustomProfileHeader from "./CustomProfileHeader";
import { SectionContainer } from "../ProductProfile/ProductProfileHeader";
import { CustomSlider } from "./CustomSlider";
import { retrieveWorkflow } from "../../../services/api";

const CustomProfile = ({
  latestTxnHash,
  profileData,
  ipfsHash,
  profileType,
  workflowRefId,
}) => {
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [showAction, setShowAction] = useState(false);
  const [verification, setVerification] = useState({
    type: null,
    fieldData: null,
  });

  const [decryptType, setDecryptType] = useState();
  const [isDecrypted, setIsDecrypted] = useState(false);
  const [showDecryption, setShowDecryption] = useState(false);
  const [currentField, setCurrentField] = useState({});
  const [isPublic, setIsPublic] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [uniqueNFTsTotalToken, setUniqueNFTsTotalToken] = useState(0);
  const [limitedSFTsTotalToken, setLimitedSFTsTotalToken] = useState(0);
  const [workflowJson, setWorkflowJson] = useState();

  const fetchWorkflow = async () => {
    if (workflowRefId) {
      try {
        const response = await retrieveWorkflow({
          path: {
            id: workflowRefId,
          },
        });
        if (response?.data?.code === 200) {
          setWorkflowJson(JSON.parse(response?.data?.data[0].file));
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    }
  };

  useEffect(() => {
    fetchWorkflow();
  }, []);

  return (
    <Fragment>
      <Container>
        <CustomProfileHeader
          profileData={profileData}
          isPublic={true}
          setIsPublic={setIsPublic}
          setCurrentField={setCurrentField}
          // openEditedDetails={openEditedDetails}
          // editedDetails={editedDetails}
          latestTxnHash={latestTxnHash}
          ipfsHash={ipfsHash}
          setVerification={setVerification}
          setShowAction={setShowAction}
          workflowJson={workflowJson}
        />
        <SectionContainer>
          <FlexColumnContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>Stages</SubHeading>
            </FlexSpaceBetweenContainer>
            <CustomSlider
              productData={profileData?.stageData}
              setShowAction={setShowAction}
              setVerification={setVerification}
              setCurrentField={setCurrentField}
              // setShowDecryption={setShowDecryption}
              // setShowEncryption={encryptData}
              latestTxnHash={latestTxnHash}
              isDecrypted={true}
              ipfsHash={ipfsHash}
              isPublic={true}
            />
            {profileData?.stageData && profileData?.stageData.length > 0 && (
              <ArrowsContainer>
                <ArrowBody className="prev">
                  <ArrowBackIosNewIcon
                    className="prev"
                    style={{ color: "#080036", cursor: "pointer" }}
                  />
                </ArrowBody>
                <ArrowBody className="next">
                  <ArrowForwardIosIcon
                    className="next"
                    style={{ color: "#080036", cursor: "pointer" }}
                  />
                </ArrowBody>
              </ArrowsContainer>
            )}
          </FlexColumnContainer>
        </SectionContainer>
      </Container>
    </Fragment>
  );
};

export default CustomProfile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;

export const ArrowBody = styled.div`
  cursor: pointer;
  display: grid;
  place-items: center;
  padding: 0.4rem;
  border: 1px solid #d3d3d3;
  border-radius: 50%;
`;

export const ArrowsContainer = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

export const IconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1.5rem 1.5rem 3rem 1.5rem;
`;

export const ViewButton = styled(Link)`
  text-decoration: none;
  padding: 0.3rem 1rem;
  background: #1fc36157;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;
  color: #080036;
  font-family: "Poppins";
`;

export const ConfigureTokenContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  // width: auto;
  // z-index: 33;
`;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
