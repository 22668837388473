import moment from "moment";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Text } from "../Typography/Typo";
import VerifiedStatusContainer from "./VerifiedStatusContainer";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { urlToHash } from "../../../services/ipfs";

const TokenCard = ({
  itemName,
  issuer,
  issueDate,
  description,
  itemImage,
  isPublic,
  status,
  fieldData,
}) => {
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };

  return (
    <Fragment>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage
              src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                itemImage
              )}`}
              alt="image"
            />
          </div>
        </ImageFlexContainer>
      </Modal>
      <Container>
        <LargeImageWrapper>
          <ProfileImage
            src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(itemImage)}`}
            alt={itemName}
            cursor
            onClick={() => {
              handleImageOpen();
            }}
          />
        </LargeImageWrapper>
        <Content>
          <FlexSpaceContainer>
            <Text primary>{itemName}</Text>
            <VerifiedStatusContainer
              // style={{ marginTop: "-3.5rem" }}
              status={status}
              isPublic={isPublic}
              fieldData={fieldData}
              fieldName={
                fieldData?.data?.position ? "Work Experience" : "Education"
              }
            />
          </FlexSpaceContainer>
          <Text primary lighter>
            {issuer}
          </Text>
          <Text lighter color="#6d6d6d">
            {moment(Number(issueDate)).format("MMM YYYY")}
          </Text>
          {description && (
            <Text style={{ marginTop: "0.5rem" }} primary lighter>
              {description}
            </Text>
          )}
        </Content>
      </Container>
    </Fragment>
  );
};

TokenCard.propTypes = {
  status: PropTypes.number,
  isPublic: PropTypes.bool,
  itemName: PropTypes.string,
  issuer: PropTypes.string,
  issueDate: PropTypes.number,
  description: PropTypes.string,
  itemImage: PropTypes.any,
  fieldData: PropTypes.object,
};

export default TokenCard;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  margin: 0.5rem 0rem;
`;

const LargeImageWrapper = styled.div`
  overflow: hidden;
  width: 6.5rem;
  height: 6.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const FlexSpaceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;
const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 60vh;
  overflow: hidden;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
