import styled from "styled-components";

import { Menu, NavTagContainer } from "./HeaderStyles";

import Logo from "../../../assets/images/logo.svg";
import { CustomButton } from "../../shared/fields/Button/CustomButton";

export const Header = () => {
  return (
    <FlexContainer primary>
      <NavTagContainer>
        <Icon src={Logo} alt="Logo" />
      </NavTagContainer>
      <Menu>
        <CustomButton
          onclick={() => {
            window.open("https://www.app.deprofile.io/login ", "_blank");
          }}
          text="Sign In"
          login
          loginIcon
          loginClass
        />

      </Menu>
    </FlexContainer>
  );
};

export const Icon = styled.img`
  width: 100%;
  width: 10rem;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
  @media (max-width: 1368px) {
    width: 8rem;
  }
`;

const FlexContainer = styled.header`
  padding: 0.5rem 2rem;
  position: sticky;
  -webkit-box-shadow: -1px 5px 18px -1px rgba(0, 0, 0, 0.57);
  box-shadow: -1px 5px 18px -1px rgba(0, 0, 0, 0.57);
  transform: ${(props) => (props.hide ? "translateY(-110%)" : "translateY(0)")};
  min-height: 50px;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #293149 0%, #1b2236 100%);
  color: ${(props) => (props.primary ? "#fff" : "#1E1B3B")};
  margin-bottom: ${(props) => (props.primary ? "1rem" : "none")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  z-index: 50;
  @media (max-width: 1368px) {
    padding: 0.3rem 1rem;
  }
`;
