import React from "react";
import { TextField } from "../Form/FormComponents/TextField";
import { SubHeading } from "../Typography/Typo";
import { SectionContainer } from "./UserProfileHeader";
import VerifiedStatusContainer from "./VerifiedStatusContainer";
import styled from "styled-components";
import MultipleImageViewSlider from "../Form/StageForm/MultipleImageViewSlider";
import CustomDetailsCard from "../Form/StageForm/CustomDetailsCard";

const AdditionalDetails = ({
  isPublic,
  profileData,
  setCurrentField,
  latestTxnHash,
  setVerification,
  setShowAction,
  ipfsHash,
  profileType,
}) => {
  var dataListfieldKeys =
    profileData?.customData?.length > 0 &&
    profileData?.customData?.map((data) => {
      const keyData = Object.keys(data?.data);
      return keyData;
    });

  var customDataListToMap =
    profileData?.customData?.length > 0 &&
    profileData?.customData?.map((data) => {
      return data?.data;
    });

  return (
    <SectionContainer>
      <SectionInnerContainer>
        <FlexSpaceBetweenContainer>
          <SubHeading primary>
            {profileType && profileType === 4
              ? "Document Details"
              : "Additional Details"}
          </SubHeading>
        </FlexSpaceBetweenContainer>{" "}
        {dataListfieldKeys &&
          dataListfieldKeys?.length > 0 &&
          dataListfieldKeys?.map((fieldName, key) => {
            if (
              Array.isArray(
                profileData?.customData[key].data[fieldName[0]].data
              )
            ) {
              return (
                <ColumnContainer span={"true"} key={key}>
                  <MultipleImageViewSlider
                    fieldName={fieldName[0]}
                    data={profileData?.customData[key].data[fieldName[0]].data}
                  />
                </ColumnContainer>
              );
            } else if (
              profileData?.customData[key].data[fieldName[0]].data.includes(
                "ipfs"
              )
            ) {
              return (
                <ColumnContainer span={"true"} key={key}>
                  <CustomDetailsCard
                    id={key}
                    viewPage={true}
                    isPublic={isPublic}
                    fieldName={fieldName[0]}
                    profileData={profileData?.customData[key]}
                    data={profileData?.customData[key].data[
                      fieldName[0]
                    ].data?.split(",")}
                    setCurrentField={setCurrentField}
                    latestTxnHash={latestTxnHash}
                    setVerification={setVerification}
                    setShowAction={setShowAction}
                    ipfsHash={ipfsHash}
                    // setEditedDetails={setEditedDetails}
                    // setImageData={setImageData}
                    // handleImageOpen={handleImageOpen}
                  />
                </ColumnContainer>
              );
            } else {
              return (
                <TextField
                  icon={
                    <VerifiedStatusContainer
                      status={0}
                      isPublic={isPublic}
                      fieldData={profileData?.customData[key]}
                      fieldId={{ id: key, name: "customData" }}
                      fieldName={fieldName[0]}
                      // isDecrypted={profileData?.customData[key].isPublic}
                      encryptedType={
                        profileData?.customData[key].data[fieldName[0]]
                          .encryptionType
                      }
                      setCurrentField={setCurrentField}
                      latestTxnHash={latestTxnHash}
                      setVerification={setVerification}
                      setShowAction={setShowAction}
                      isDecrypted={true}
                      ipfsHash={ipfsHash}
                      // setOpenEditedDetails={setOpenEditedDetails}
                      // setEditedDetails={setEditedDetails}
                    />
                  }
                  disabled
                  key={key}
                  label={
                    fieldName[0]
                      .toString()
                      .replace(/([A-Z])/g, " $1")
                      .substring(0, 1)
                      .toUpperCase() +
                    fieldName[0]
                      .toString()
                      .replace(/([A-Z])/g, " $1")
                      .substring(1)
                  }
                  value={
                    profileData?.customData[key].isPublic === true
                      ? profileData?.customData[key].data[fieldName[0]]?.data
                      : Hash.repeat(
                          profileData?.customData[key].data[fieldName[0]]?.data
                            .length
                        )
                  }
                />
              );
            }
          })}
      </SectionInnerContainer>
    </SectionContainer>
  );
};

export default AdditionalDetails;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;
