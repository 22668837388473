import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Heading, Text } from "../Typography/Typo";
import VerifiedStatusContainer from "./VerifiedStatusContainer";
import { FieldWrapper } from "./UserProfile";
import { Modal } from "@mui/material";
import ContactInfo from "./ContactInfo";
import { urlToHash } from "../../../services/ipfs";

const UserProfileHeader = ({ profileData, isPublic }) => {
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };

  const [openContact, setContactOpen] = useState(false);
  const handleContactOpen = () => setContactOpen(true);
  const handleContactClose = () => {
    setContactOpen(false);
  };

  return (
    <Fragment>
      <Modal
        open={openContact}
        onClose={handleContactClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactInfo profileData={profileData} />
      </Modal>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage
              src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(profileData?.profileImage?.data?.data)}`}
              alt="image"
            />
          </div>
        </ImageFlexContainer>
      </Modal>
      <SectionContainer>
        <Banner bannerImage="https://images.unsplash.com/photo-1557683304-673a23048d34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fGJhY2tncm91bmR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60">
          <ImageWrapper>
            <ProfileImage
              alt="Profile Picture"
              cursor
              src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(profileData?.profileImage?.data?.data)}`}
              onClick={() => {
                handleImageOpen();
              }}
            />
          </ImageWrapper>
        </Banner>
        <SectionInnerContainer>
          <ContentContainer>
            <FieldWrapper>
              <Heading
                primary
              >{`${profileData?.firstName?.data?.data} ${profileData?.lastName?.data?.data}`}</Heading>
              <VerifiedStatusContainer
                status={0}
                isPublic={isPublic}
                fieldData={`${profileData?.firstName?.data?.data} ${profileData?.lastName?.data?.data}`}
                fieldName="Name"
              />
            </FieldWrapper>

            {profileData?.workData?.length > 0 ? (
              <FieldWrapper>
                <Text
                  primary
                >{`${profileData?.workData[0]?.data?.position?.data} at ${profileData?.workData[0]?.data?.company?.data}`}</Text>{" "}
                <VerifiedStatusContainer
                  status={0}
                  isPublic={isPublic}
                  fieldData={`${profileData?.workData[0]?.data?.position?.data} at ${profileData?.workData[0]?.data?.company?.data}`}
                  fieldName="Current Work Place"
                />
              </FieldWrapper>
            ) : (
              profileData?.educationData?.length > 0 && (
                <FieldWrapper>
                  <Text
                    primary
                  >{`Student at ${profileData?.educationData[0]?.data?.institutionName?.data}`}</Text>
                  <VerifiedStatusContainer
                    status={0}
                    isPublic={isPublic}
                    fieldData={`Student at ${profileData?.educationData[0]?.data?.institutionName?.data}`}
                    fieldName="Student at"
                  />
                </FieldWrapper>
              )
            )}
            <FieldWrapper>
              <Text lighter color="#6d6d6d">
                {`${profileData?.city?.data?.data} District, Western,  ${profileData?.country?.data?.data}`}
              </Text>
              <VerifiedStatusContainer
                status={0}
                isPublic={isPublic}
                fieldData={`${profileData?.city?.data?.data} District, Western,  ${profileData?.country?.data?.data}`}
                fieldName="Lives in"
              />
            </FieldWrapper>
            <FieldWrapper>
              <SpanLink onClick={handleContactOpen}>Contact Info</SpanLink>
            </FieldWrapper>
          </ContentContainer>
          <ContentContainer style={{ gap: "0.8rem" }}>
            {profileData?.workData?.length > 0 && (
              <FlexContainer>
                <SmallImageWrapper>
                  <ProfileImage
                    src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(profileData?.workData[0]?.data?.imageLogo?.data)}`}
                  />
                </SmallImageWrapper>
                <Text primary>
                  {profileData?.workData[0]?.data?.company?.data}
                </Text>
              </FlexContainer>
            )}
            {profileData?.educationData?.length > 0 && (
              <FlexContainer>
                <SmallImageWrapper>
                  <ProfileImage
                    src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(profileData?.educationData[0]?.data?.imageLogo?.data)}`}
                  />
                </SmallImageWrapper>
                <Text primary>
                  {profileData?.educationData[0]?.data?.institutionName?.data}
                </Text>
              </FlexContainer>
            )}
          </ContentContainer>
        </SectionInnerContainer>
      </SectionContainer>
    </Fragment>
  );
};

export default UserProfileHeader;

export const SectionContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border-radius: 10px;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  margin-top: 3rem;
  margin-left: 2rem;
  @media (max-width: 1368px) {
    margin-top: 3rem;
    margin-left: 1rem;
    width: 8rem;
    height: 8rem;
  }
`;

export const SpanLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #1696ff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const Banner = styled.div`
  position: relative;
  background-image: url(${(props) => props.bannerImage});
  height: 10rem;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1368px) {
    height: 8rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const SmallImageWrapper = styled.div`
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

export const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;

export const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 60vh;
  overflow: hidden;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
