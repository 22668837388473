import React, { Fragment, useEffect, useState } from "react";
import SwiperCore, {
  Keyboard,
  Pagination,
  Navigation,
  Scrollbar,
  Mousewheel,
} from "swiper";
import { Modal } from "@mui/material";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

import fileTypePng from "../../../../assets/images/fileTypePng.png";
import fileTypeJpeg from "../../../../assets/images/fileTypeJpeg.png";
import fileTypePdf from "../../../../assets/images/fileTypePdf.png";
import googleDoc from "../../../../assets/images/googleDocs.png";

// install Swiper modules
SwiperCore.use([Keyboard, Pagination, Navigation, Scrollbar, Mousewheel]);

const MultipleFileTypeCard = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => setImageOpen(false);
  const [currentImage, setImage] = useState();

  const getFileType = async (fileURL) => {
    if (fileURL) {
      setLoading(true);
      let file = fileURL;
      var req = await fetch(file, { method: "HEAD" });
      setLoading(false);
      return setFileType(req.headers.get("content-type"));
    }
  };

  useEffect(() => {
    if (data && data.length > 0) getFileType(data[0]);
  }, []);

  return (
    <>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage src={currentImage} alt="image" />
          </div>
        </ImageFlexContainer>
      </Modal>
      <div style={{ display: "flex", flexdirection: "row", cursor: "pointer" }}>
        {data &&
          data.length > 0 &&
          data.map((item, key) => {
            return loading ? (
              <CircularProgress size={20} />
            ) : fileType === "application/pdf" ? (
              <img
                style={{
                  width: "80px",
                  height: "80px",
                  color: "red",
                }}
                onClick={() => window.open(item)}
                src={fileTypePdf}
                alt={"pdf"}
                loading="lazy"
              />
            ) : fileType === "image/png" ? (
              <img
                style={{
                  width: "80px",
                  height: "80px",
                  color: "red",
                }}
                onClick={() => {
                  setImage(item);
                  handleImageOpen();
                }}
                src={fileTypePng}
                alt={"png"}
                loading="lazy"
              />
            ) : fileType === "image/jpeg" ? (
              <img
                style={{
                  width: "80px",
                  height: "80px",
                  color: "red",
                }}
                onClick={() => {
                  setImage(item);
                  handleImageOpen();
                }}
                src={fileTypeJpeg}
                alt={"jpeg"}
                loading="lazy"
              />
            ) : (
              <img
                style={{
                  width: "80px",
                  height: "80px",
                  color: "red",
                }}
                onClick={() => {
                  setImage(item);
                  handleImageOpen();
                }}
                src={googleDoc}
                alt={"docs"}
                loading="lazy"
              />
            );
          })}
      </div>
    </>
  );
};

export default MultipleFileTypeCard;

const Container = styled.div`
  padding: 1rem;
  flex-direction: column;
  height: 100%;
  width: ${(props) => (props.fileType === "application/pdf" ? "470px" : "")};
  display: flex;
  z-index: 30;
  justify-content: space-between;
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  gap: 0.5rem;
`;

const PdfViewContainer = styled.div`
  // max-width: 350px;
  // max-height: 350px;
  // min-width: 20px;
  // min-height: 20px;
  height: 300px;
  width: 300px;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  &:before {
    // content: "";
    // background: url("https://c.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif");
    // min-width: 350px;
    // min-height: 350px;
    height: 300px;
    width: 300px;
    object-fit: contain;
    object-position: center;
    display: block;
    background: #ffffff;
  }
  @media (max-width: 768px) {
    // max-width: 250px;
    // max-height: 250px;
    // min-width: 250px;
    // min-height: 250px;
    height: 300px;
    width: 300px;
  }
  cursor: pointer;
`;
const OverFlowWrap = styled.img`
  // max-width: 350px;
  // max-height: 350px;
  // min-width: 20px;
  // min-height: 20px;
  height: 300px;
  width: 300px;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  &:before {
    content: "";
    background: url("https://c.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif");
    // min-width: 350px;
    // min-height: 350px;
    height: 300px;
    width: 300px;
    object-fit: contain;
    object-position: center;
    display: block;
    background: #ffffff;
  }
  @media (max-width: 768px) {
    // max-width: 250px;
    // max-height: 250px;
    // min-width: 250px;
    // min-height: 250px;
    height: 300px;
    width: 300px;
  }
  cursor: pointer;
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;

const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;
