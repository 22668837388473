import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Heading, Text } from "../Typography/Typo";
import { IconButton, Modal } from "@mui/material";
import { FieldWrapper, FlexSpaceBetweenContainer } from "./ProductProfile";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ShareIcon from "@mui/icons-material/Share";
import VerifiedStatusContainer from "../Profile/VerifiedStatusContainer";
import { SpanLink } from "../Profile/UserProfileHeader";
import ContactInfo from "../Profile/ContactInfo";
import { urlToHash } from "../../../services/ipfs";

const ProductProfileHeader = ({
  profileData,
  isPublic,
  setCurrentField,
  // openEditedDetails,
  // editedDetails,
  latestTxnHash,
  setVerification,
  setShowAction,
  ipfsHash,
}) => {
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };

  const [openContact, setContactOpen] = useState(false);
  const handleContactOpen = () => setContactOpen(true);
  const handleContactClose = () => {
    setContactOpen(false);
  };

  return (
    <Fragment>
      <Modal
        open={openContact}
        onClose={handleContactClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactInfo profileData={profileData} />
      </Modal>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage
              src={
                profileData?.productImage?.data?.data === "undefined"
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      profileData?.productImage?.data?.data
                    )}`
              }
              alt="image"
            />
          </div>
        </ImageFlexContainer>
      </Modal>
      <SectionContainer>
        <Banner>
          <ImageWrapper>
            <ProfileImage
              alt="Product Picture"
              cursor
              src={
                profileData?.productImage?.data?.data === "undefined"
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      profileData?.productImage?.data?.data
                    )}`
              }
              onClick={() => {
                handleImageOpen();
              }}
            />
          </ImageWrapper>
        </Banner>
        <SectionInnerContainer>
          <ContentContainer>
            <FieldWrapper>
              <Heading primary>{profileData?.productName?.data?.data}</Heading>
              <VerifiedStatusContainer
                isPublic={isPublic}
                fieldData={profileData?.productName}
                fieldName="Product Name"
                fieldId={{ id: "documentName" }}
                setCurrentField={setCurrentField}
                // openEditedDetails={openEditedDetails}
                // editedDetails={editedDetails}
                latestTxnHash={latestTxnHash}
                setVerification={setVerification}
                setShowAction={setShowAction}
                isDecrypted={true}
                ipfsHash={ipfsHash}
              />
            </FieldWrapper>

            {profileData?.stageData?.length > 0 ? (
              <FieldWrapper>
                <Text
                  primary
                >{`Operating Stage ${profileData?.stageData[0].data.stageName.data}`}</Text>{" "}
                <VerifiedStatusContainer
                  isPublic={isPublic}
                  fieldData={
                    profileData?.stageData[profileData?.stageData?.length - 1]
                  }
                  fieldName="Operating Stage"
                  fieldId={{
                    id: profileData?.stageData?.length - 1,
                    name: "stageData",
                  }}
                  setCurrentField={setCurrentField}
                  // openEditedDetails={openEditedDetails}
                  // editedDetails={editedDetails}
                  latestTxnHash={latestTxnHash}
                  setVerification={setVerification}
                  setShowAction={setShowAction}
                  isDecrypted={true}
                  ipfsHash={ipfsHash}
                />
              </FieldWrapper>
            ) : (
              profileData?.email?.data?.data && (
                <FieldWrapper>
                  <Text
                    primary
                  >{`Product Queries ${profileData?.email?.data?.data}`}</Text>
                  <VerifiedStatusContainer
                    status={1}
                    isPublic={isPublic}
                    fieldData={`Queries: ${currentUser.email}`}
                    fieldName="Product Email"
                    latestTxnHash={latestTxnHash}
                    setVerification={setVerification}
                    setShowAction={setShowAction}
                    isDecrypted={true}
                    ipfsHash={ipfsHash}
                  />
                </FieldWrapper>
              )
            )}
            {/* <FieldWrapper>
              <SpanLink onClick={handleContactOpen}>Contact Info</SpanLink>
            </FieldWrapper> */}
            {/* <FieldWrapper>
              <Text lighter color="#6d6d6d">
                {`${profileData?.city?.data?.data} District, Western,  ${profileData?.country?.data?.data}`}
              </Text>
              <VerifiedStatusContainer
                status={1}
                isPublic={isPublic}
                fieldData={`${profileData?.city?.data?.data} District, Western,  ${profileData?.country?.data?.data}`}
                fieldName="Lives in"
                setShowAction={setShowAction}
                setVerification={setVerification}
              />
            </FieldWrapper> */}
          </ContentContainer>
          {/* <ContentContainer style={{ gap: "0.8rem" }}>
            {profileData?.workData?.length > 0 && (
              <FlexContainer>
                <SmallImageWrapper>
                  <ProfileImage
                    src={profileData?.workData[0]?.data?.imageLogo?.data}
                  />
                </SmallImageWrapper>
                <Text primary>
                  {profileData?.workData[0]?.data?.company?.data}
                </Text>
              </FlexContainer>
            )}
            {profileData?.educationData?.length > 0 && (
              <FlexContainer>
                <SmallImageWrapper>
                  <ProfileImage
                    src={profileData?.educationData[0]?.data?.imageLogo?.data}
                  />
                </SmallImageWrapper>
                <Text primary>
                  {profileData?.educationData[0]?.data?.institutionName?.data}
                </Text>
              </FlexContainer>
            )}
          </ContentContainer> */}
        </SectionInnerContainer>
      </SectionContainer>
    </Fragment>
  );
};

export default ProductProfileHeader;

export const SectionContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border-radius: 10px;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  margin-top: 3rem;
  margin-left: 2rem;
  @media (max-width: 1368px) {
    margin-top: 3rem;
    margin-left: 1rem;
    width: 8rem;
    height: 8rem;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const Banner = styled.div`
  position: relative;
  background: linear-gradient(45deg, #0575e6 1%, #00f260 100%);
  height: 10rem;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1368px) {
    height: 8rem;
  }
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 4%;
  right: 1.5%;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
`;
export const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;
export const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 60vh;
  overflow: hidden;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
