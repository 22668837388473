import React, { Fragment, useEffect, useState } from "react";
import { GetIPFSData, me } from "../../services/api.js";
import { urlToHash } from "../../services/ipfs";
import InnerLoadingView from "../shared/loading/InnerLoadingView.js";
import UserProfile from "../shared/Profile/UserProfile";
import { SubHeading, Text } from "../shared/Typography/Typo.js";
import Token, { Error, ErrorContainer } from "./Token.js";
import ErrorBG from "../../assets/images/not-found.gif";
import ProviderView from "../shared/ProviderProfile/ProviderView.js";
import { retrieveALL } from "../../services/api";
import ProductProfile from "../shared/ProductProfile/ProductProfile.js";
import DocumentProfile from "../shared/DocumentProfile/DocumentProfile.js";
import CustomProfile from "../shared/CustomProfile/CustomProfile.js";
import EntityProfile from "../shared/EntityProfile/EntityProfile.js";

const ObjectSwitch = ({ publicKey, tokenData, setTokenData }) => {
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [latestTxnHash, setlatestTxnHash] = useState();
  const [ipfsHash, setIpfsHash] = useState("");
  const [meData, setMeData] = useState();
  const [loading, setLoading] = useState(false);

  const fetchLatestProfileData = async () => {
    setLoading(true);
    try {
      const meData = await retrieveALL({
        query: {
          id: publicKey,
        },
      });

      if (meData && meData?.data?.data?.length > 0) {
        setMeData(meData?.data?.data[0]);
        const hash = urlToHash(meData?.data?.data[0]?.latestIpfsUrl?.ipfsUrl);
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
        setlatestTxnHash(meData?.data?.data[0]?.latestIpfsUrl?.txnHash);
        setIpfsHash(hash);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLatestProfileData();
  }, []);

  return (
    <Fragment>
      {(() => {
        if (loading) {
          return <InnerLoadingView style={{ marginTop: "15rem" }} />;
        } else if (meData?.uniqueId) {
          return (
            <Token
              id={publicKey}
              setTokenData={setTokenData}
              tokenData={tokenData}
            />
          );
        } else if (meData?.type === "CREDENTIALPROVIDER") {
          return <ProviderView profileData={latestIpfsData} />;
        } else if (meData?.type === "PROFILE" && meData?.profileType === 0) {
          return (
            <CustomProfile
              profileData={latestIpfsData}
              latestTxnHash={latestTxnHash}
              ipfsHash={ipfsHash}
              profileType={meData?.profileType}
              workflowRefId={meData?.workflowRefId}
            />
          );
        } else if (meData?.type === "PROFILE" && meData?.profileType === 1) {
          return <UserProfile profileData={latestIpfsData} />;
        } else if (meData?.type === "PROFILE" && meData?.profileType === 2) {
          return <EntityProfile profileData={latestIpfsData} />;
        } else if (meData?.profileType === 3) {
          return (
            <ProductProfile
              profileData={latestIpfsData}
              latestTxnHash={latestTxnHash}
              ipfsHash={ipfsHash}
              profileType={meData?.profileType}
            />
          );
        } else if (meData?.type === "PROFILE" && meData?.profileType === 4) {
          return (
            <DocumentProfile
              profileData={latestIpfsData}
              latestTxnHash={latestTxnHash}
              ipfsHash={ipfsHash}
              profileType={meData?.profileType}
            />
          );
        } else {
          return (
            <ErrorContainer style={{ marginTop: "5rem" }}>
              <Error bg={ErrorBG} width="500px">
                <SubHeading primary>{`Item Not Found!`}</SubHeading>
              </Error>
              <Text primary>Looks like you're lost</Text>
            </ErrorContainer>
          );
        }
      })()}
    </Fragment>
  );
};

export default ObjectSwitch;
