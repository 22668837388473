import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, CircularProgress, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";

import MultipleSliderView from "./MultipleSliderView";
import { ProductHeader } from "../../fields/Header/ProductHeader";
import { Text } from "../../Typography/Typo";
import VerifiedStatusContainer from "../../Profile/VerifiedStatusContainer";
import { urlToHash } from "../../../../services/ipfs";
import { retrieveTransaction } from "../../../../services/api";
import seeBlockchain from "../../../../assets/images/seeBlockchain.png";

export const SliderComponent = ({
  item,
  first,
  addPadding,
  isPublic,
  setViewVerifiedData,
  setCurrentField,
  data,
  latestTxnHash,
  ipfsHash,
  setShowAction,
  setVerification,
  isDecrypted,
  encryptedType,
  style,
  type,
  fieldId,
  fieldName,
}) => {
  const [urlName, setUrlName] = useState("polygonscan");
  const [netowrk, setNetwork] = useState("");

  var dataListfieldKeys = item?.customStageData?.data
    ? item?.customStageData?.data.map((data) => {
        const keyData = Object.keys(data);
        return keyData;
      })
    : item.customStageData.map((data) => {
        const keyData = Object.keys(data);
        return keyData;
      });

  var customData = item?.customStageData?.data
    ? item?.customStageData?.data.map((data) => {
        return data;
      })
    : item.customStageData.map((data) => {
        return data;
      });

  const positionRef = useRef({
    x: 0,
    y: 0,
  });
  const handleActionOpen = () => {
    setShowAction(true);
  };

  const fetchTransactionDetails = async () => {
    const res = await retrieveTransaction({
      uniqueId: ipfsHash,
    });

    if (res !== undefined) {
      switch (res.data.data[0].network) {
        case "MUMBAI":
          setNetwork("mumbai.polygonscan");
          break;
        case "ETHEREUM":
          setNetwork("etherscan");
          break;
        case "RINKEBY":
          setNetwork("rinkeby.etherscan");
          break;
        case "BSC":
          setNetwork("bscscan");
          break;
        case "BSCTESTNET":
          setNetwork("testnet.bscscan");
          break;
        case "MATIC":
          setNetwork("polygonscan");
          break;
        default:
          setNetwork("polygonscan");
      }
    }
  };

  useEffect(() => {
    switch (item?.blockchain?.data) {
      case "MUMBAI":
        setUrlName("mumbai.polygonscan");
        break;
      case "ETHEREUM":
        setUrlName("etherscan");
        break;
      case "RINKEBY":
        setUrlName("rinkeby.etherscan");
        break;
      case "BSC":
        setUrlName("bscscan");
        break;
      case "BSCTESTNET":
        setUrlName("testnet.bscscan");
        break;
      case "MATIC":
        setUrlName("polygonscan");
        break;
      default:
        setUrlName("polygonscan");
    }
  }, [item?.blockchain]);

  useEffect(() => {
    latestTxnHash && fetchTransactionDetails();
  }, []);

  const popperRef = useRef(null);
  const areaRef = useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  if (!first) {
    return (
      <Container>
        <TextContainer>
          <HeaderFlexContainer>
            <Text lighter primary>
              {item?.stageName?.data ? item?.stageName?.data : item?.stageName}
            </Text>

            {/* Encryption icon */}
            {isDecrypted && encryptedType === "SYMMETRIC" ? (
              <IconButton>
                <LockOpenIcon
                  onClick={() => {
                    var foo = setCurrentField;
                    if (typeof foo === "function") {
                      setCurrentField && setCurrentField(fieldId);
                      // setShowEncryption && setShowEncryption(fieldId);
                    }
                  }}
                />
              </IconButton>
            ) : (
              ""
            )}

            {/* Verification icon */}
            <InnerContainer>
              {(() => {
                if (
                  data?.verifiedBy &&
                  data?.verifiedBy.find(
                    (item) => item?.verificationStatus === 0
                  ) &&
                  isPublic
                ) {
                  return (
                    <Tooltip
                      placement="top"
                      arrow
                      title="Verification Pending"
                      PopperProps={{
                        popperRef,
                      }}
                    >
                      <StatusContainer
                        style={style}
                        status={0}
                        ref={areaRef}
                        onClick={() => {
                          handleActionOpen();
                          if (setVerification) {
                            setCurrentField && setCurrentField(fieldId);
                            setVerification({
                              type: fieldName,
                              fieldData: data,
                              isPublic: isDecrypted,
                            });
                          }
                        }}
                      >
                        <Box color="#fa7d25fc" />
                      </StatusContainer>
                    </Tooltip>
                  );
                } else if (isPublic && data?.verifiedBy?.length === 0) {
                  return (
                    <Tooltip
                      arrow
                      placement="top"
                      title="Unverified"
                      PopperProps={{
                        popperRef,
                      }}
                    >
                      <StatusContainer
                        style={style}
                        onClick={() => {
                          handleActionOpen();
                          if (setVerification) {
                            setCurrentField && setCurrentField(fieldId);
                            setVerification({
                              type: fieldName,
                              fieldData: data,
                              isPublic: isDecrypted,
                            });
                          }
                        }}
                        status={status}
                        ref={areaRef}
                        onMouseMove={handleMouseMove}
                      >
                        <Box color="#9b9b9b" />
                      </StatusContainer>
                    </Tooltip>
                  );
                } else if (
                  data?.verifiedBy &&
                  (data?.verifiedBy.find(
                    (item) => item?.verificationStatus === 1
                  ) ||
                    data?.verifiedBy.length !== 0) &&
                  isPublic
                ) {
                  return (
                    <Tooltip
                      arrow
                      placement="top"
                      title="Verified"
                      PopperProps={{
                        popperRef,
                      }}
                    >
                      <StatusContainer
                        style={style}
                        onClick={() => {
                          handleActionOpen();
                          if (setVerification) {
                            setCurrentField && setCurrentField(fieldId);
                            setVerification({
                              type: fieldName,
                              fieldData: data,
                              isPublic: isDecrypted,
                            });
                          }
                        }}
                        status={status}
                        ref={areaRef}
                        onMouseMove={handleMouseMove}
                      >
                        <Box color="#59D88C" />
                      </StatusContainer>
                    </Tooltip>
                  );
                } else if (
                  data?.verifiedBy &&
                  data?.verifiedBy.find(
                    (item) => item?.verificationStatus === 2
                  ) &&
                  isPublic
                ) {
                  return (
                    <Tooltip
                      arrow
                      placement="top"
                      title="Unverified"
                      PopperProps={{
                        popperRef,
                      }}
                    >
                      <StatusContainer
                        style={style}
                        onClick={() => {
                          handleActionOpen();
                          if (setVerification) {
                            setCurrentField && setCurrentField(fieldId);
                            setVerification({
                              type: fieldName,
                              fieldData: data,
                              isPublic: isDecrypted,
                            });
                          }
                        }}
                        status={status}
                        ref={areaRef}
                        onMouseMove={handleMouseMove}
                      >
                        <Box color="#9b9b9b" />
                      </StatusContainer>
                    </Tooltip>
                  );
                }
              })()}
              {/* &nbsp; */}
              {/* View edited details */}
              {/* {isPublic && data.editedBy.length > 0 && (
                <Tooltip
                  arrow
                  placement="top"
                  title="View edited details"
                  PopperProps={{
                    popperRef,
                  }}
                >
                  <StatusContainer
                    style={style}
                    onClick={() => {
                      setOpenEditedDetails(true);
                      setEditedDetails && setEditedDetails(data.editedBy[0]);
                    }}
                    status={status}
                    ref={areaRef}
                    onMouseMove={handleMouseMove}
                  >
                    <Box color="#0575e6" />
                  </StatusContainer>
                </Tooltip>
              )} */}
              {!type && latestTxnHash && netowrk !== "" && (
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#2e8b57",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(`https://${netowrk}.com/tx/${latestTxnHash}`)
                  }
                  src={seeBlockchain}
                  alt={"pdf"}
                  loading="lazy"
                />
              )}
            </InnerContainer>

            {/* Blockchain icon */}
            {item?.transactionHash && (
              <Tooltip
                placement="top"
                arrow
                title="See Blockchain Transaction"
                PopperProps={{
                  popperRef,
                  anchorEl: {
                    getBoundingClientRect: () => {
                      return new DOMRect(
                        positionRef.current.x,
                        areaRef.current.getBoundingClientRect().y,
                        0,
                        0
                      );
                    },
                  },
                }}
              >
                <StatusContainer
                  style={style}
                  status={0}
                  ref={areaRef}
                  onMouseMove={handleMouseMove}
                >
                  <Link
                    target="_blank"
                    href={`https://${urlName}.com/tx/${item?.transactionHash?.data}`}
                  >
                    <ArticleTwoToneIcon
                      sx={{ fontSize: 18 }}
                      style={{ color: "#646464" }}
                    />
                  </Link>
                </StatusContainer>
              </Tooltip>
            )}
          </HeaderFlexContainer>
        </TextContainer>
        <ArrowContainer>
          <ArrowBody>
            <ArrowHead>
              <ArrowSpan />
            </ArrowHead>
          </ArrowBody>
        </ArrowContainer>
        <CardContainer>
          <ProductHeader
            addPadding={addPadding}
            productName={
              item?.stageName?.data ? item?.stageName?.data : item?.stageName
            }
            id={
              item?.id.data
                ? item?.id?.data.toString().substring(0, 10 - 3)
                : item?.id.toString().substring(0, 10 - 3)
            }
            description={
              item?.stageDescription?.data
                ? item?.stageDescription?.data
                : item?.stageDescription
            }
          />
          <FieldWrapper>
            <Text primary lighter small style={{ justifySelf: "start" }}>
              {`${
                item?.stageName?.data ? item?.stageName?.data : item?.stageName
              } Image`}
            </Text>
            <Image
              loading="lazy"
              src={
                item?.stageImage?.data
                  ? `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      item?.stageImage?.data
                    )}`
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      item?.stageImage
                    )}`
              }
              alt={
                item.stageName?.data ? item?.stageName?.data : item?.stageName
              }
            />
          </FieldWrapper>
          {dataListfieldKeys &&
            dataListfieldKeys.length > 0 &&
            dataListfieldKeys.map((fieldName, key) => {
              let fieldData = customData[key][fieldName[0]];

              if (fieldName[0] === "isPublic") {
                return null;
              } else if (fieldData?.toString()?.includes("ipfs")) {
                return (
                  <MultipleSliderView
                    key={key}
                    data={fieldData?.toString()?.split(",")}
                    fieldName={fieldName[0]}
                  />
                );
              } else {
                return (
                  <FieldWrapper key={key}>
                    <Text
                      primary
                      lighter
                      small
                      style={{ justifySelf: "start" }}
                    >
                      {fieldName[0]
                        .toString()
                        .replace(/([A-Z])/g, " $1")
                        .substring(0, 1)
                        .toUpperCase() +
                        fieldName[0]
                          .toString()
                          .replace(/([A-Z])/g, " $1")
                          .substring(1)}
                    </Text>
                    <Text
                      primary
                      lighter
                      small
                      style={{ justifySelf: "end", wordBreak: "break-word" }}
                    >
                      {fieldData}
                    </Text>
                  </FieldWrapper>
                );
              }
            })}
        </CardContainer>
      </Container>
    );
  } else {
    return (
      <Container>
        <TextContainer>
          <Text primary>
            {item?.addedDate?.data ? item?.addedDate?.data : item?.addedDate}{" "}
          </Text>
        </TextContainer>
        <ArrowContainer>
          <ArrowBody>
            <ArrowHead>
              <ArrowSpan />
            </ArrowHead>
          </ArrowBody>
        </ArrowContainer>
        <CardContainer>
          <ProductHeader
            addPadding={addPadding}
            productName={
              item?.stageName?.data ? item?.stageName?.data : item?.stageName
            }
            id={
              item?.id?.data
                ? item?.id?.data.substring(0, 11 - 3)
                : item?.data.substring(0, 11 - 3)
            }
            description={
              item.stageDescription?.data
                ? item?.stageDescription?.data
                : item?.stageDescription
            }
          />
          {/* {dataListfieldKeys &&
            dataListfieldKeys.length > 0 &&
            dataListfieldKeys.map((fieldName, key) => {
              if (fieldName[0] === "sliderData") {
                return (
                  <ColumnContainer span={"true"}>
                    <SlideView
                      customImageArr={
                        profileData?.customData[key].data[fieldName[0]]?.data
                      }
                    />
                  </ColumnContainer>
                );
              } else {
                return (
                  <FieldWrapper key={key}>
                    <Text
                      primary
                      lighter
                      small
                      style={{ justifySelf: "start" }}
                    >
                      {fieldName[0]
                        .toString()
                        .replace(/([A-Z])/g, " $1")
                        .substring(0, 1)
                        .toUpperCase() +
                        fieldName[0]
                          .toString()
                          .replace(/([A-Z])/g, " $1")
                          .substring(1)}
                    </Text>
                    <Text
                      primary
                      lighter
                      small
                      style={{ justifySelf: "end", wordBreak: "break-word" }}
                    >
                      {customData[0][fieldName[0]]}
                    </Text>
                  </FieldWrapper>
                );
              }
            })} */}

          {dataListfieldKeys &&
            dataListfieldKeys.length > 0 &&
            dataListfieldKeys.map((fieldName, key) => {
              let fieldData = customData[key][fieldName[0]];

              if (fieldName[0] === "isPublic") {
                return null;
              } else if (fieldData?.toString()?.includes("ipfs")) {
                return (
                  <MultipleSliderView
                    key={key}
                    data={fieldData?.toString()?.split(",")}
                    fieldName={fieldName[0]}
                  />
                );
              } else {
                return (
                  <FieldWrapper key={key}>
                    <Text
                      primary
                      lighter
                      small
                      style={{ justifySelf: "start" }}
                    >
                      {fieldName[0]
                        .toString()
                        .replace(/([A-Z])/g, " $1")
                        .substring(0, 1)
                        .toUpperCase() +
                        fieldName[0]
                          .toString()
                          .replace(/([A-Z])/g, " $1")
                          .substring(1)}
                    </Text>
                    <Text
                      primary
                      lighter
                      small
                      style={{ justifySelf: "end", wordBreak: "break-word" }}
                    >
                      {fieldData}
                    </Text>
                  </FieldWrapper>
                );
              }
            })}

          {/* <FieldWrapper addPadding={addPadding}>
            <Text primary lighter small style={{ justifySelf: "start" }}>
              Transaction Hash
            </Text>
            <HyperLink
              primary
              lighter
              small
              target="_blank"
              href={`https://laboratory.stellar.org/#explorer?resource=operations&endpoint=for_transaction&values=${encodeURIComponent(
                btoa(
                  JSON.stringify({
                    transaction: props.data.txnHash,
                  })
                )
              )}&network=test`}
              style={{ justifySelf: "end", wordBreak: "break-word" }}
            >
              {data?.txnHash}
            </HyperLink>
          </FieldWrapper> */}
        </CardContainer>
      </Container>
    );
  }
};

const Container = styled.div`
  flex-direction: column;
  display: flex;
  min-width: 20rem;
`;

const CardContainer = styled.div`
  flex-direction: column;
  display: flex;
  z-index: 30;
  justify-content: space-between;
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  gap: 0.5rem;
  padding: ${(props) => (props.addPadding ? "1.5rem" : "1rem")};
`;

const ArrowContainer = styled.div`
  height: 5rem;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ArrowBody = styled.div`
  width: 0.5rem;
  position: relative;
  height: 100%;
  background-color: #c3d0e1;
  margin-top: 0.5rem;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: ${(props) => props.status !== 1 && "pointer"};
`;

const ArrowHead = styled.div`
  width: 1.5rem;
  border-radius: 50px;
  display: grid;
  place-items: center;
  position: absolute;
  top: -10%;
  left: -100%;
  height: 1.5rem;
  background-color: #c3d0e1;
`;

const ArrowSpan = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50px;
  background-color: #080036;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: center;
  justify-content: center;
  padding: 1rem;
`;

export const HyperLink = styled.a`
  font-size: ${(props) => (props.small ? "0.875rem" : "1.125rem")};
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const FieldWrapper = styled.div`
  padding: ${(props) => (props.addPadding ? "1rem 1rem" : "0.2rem 1rem")};
  background-color: #c3d0e1;
  display: grid;
  border-radius: 5px;
  grid-template-columns: 1fr 1fr;
`;

const Image = styled.img`
  width: 4rem;
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  justify-self: end;
  border-radius: 5px;
  text-align: center;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;
const HeaderFlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Box = styled.div`
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  gap: 0.7rem;
  justify-content: center;
  align-items: center;
`;
