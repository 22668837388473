import DOMPurify from "dompurify";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import AdditionalDetails from "../Profile/AdditionalDetails";
import {
  FlexSpaceBetweenContainer,
  SectionInnerContainer,
} from "../Profile/UserProfile";
import VerifiedStatusContainer from "../Profile/VerifiedStatusContainer";
import { SubHeading } from "../Typography/Typo";
import ProviderProfileHeader, { SectionContainer } from "./ProviderProfileHeader";

const ProviderView = ({ profileData }) => {
  const [isPublic, setIsPublic] = useState(true);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  var html = profileData?.description?.data?.data;
  var div = document.createElement("p");
  div.innerHTML = html;

  return (
    <Fragment>
      <Container>
        <ProviderProfileHeader profileData={profileData} isPublic={isPublic} />
        <SectionContainer>
          <SectionInnerContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>About</SubHeading>{" "}
              <VerifiedStatusContainer status={0} isPublic={isPublic} />
            </FlexSpaceBetweenContainer>
            <Preview
              className="preview"
              dangerouslySetInnerHTML={createMarkup(
                profileData?.description?.data?.data
              )}
            />
          </SectionInnerContainer>
        </SectionContainer>
        <AdditionalDetails isPublic={isPublic} profileData={profileData} />
      </Container>
    </Fragment>
  );
};

export default ProviderView;

export const Preview = styled.div`
  line-height: 2rem;
  margin-bottom: 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;
