import { Divider } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { IconWrapper } from "../../../App";
import { Text } from "../Typography/Typo";

const ContactInfo = ({ profileData }) => {
  return (
    <ContactContainer>
      <FlexContainer>
        <Text primary>
          {profileData?.fullName?.data?.data
            ? `${profileData?.firstName?.data?.data} ${profileData?.lastName?.data?.data}`
            : profileData?.name?.data?.data
            ? profileData?.name?.data?.data
            : profileData?.productName?.data?.data}
        </Text>
        <Text primary lighter>
          Contact Info
        </Text>
      </FlexContainer>
      <Divider
        style={{
          background: "#36353511",
          width: "100%",
          margin: "0.5rem 0rem",
        }}
      />
      <ContactInnerColumnFlex>
        <ContactFlexContainer addPadding>
          <IconOuter>
            <IconWrapper>
              <span className="material-icons ico-md">{"phone"}</span>
            </IconWrapper>
          </IconOuter>
          <FlexColumn>
            <Text primary>Phone</Text>{" "}
            <Link
              primary
              lighter
              small
              href={`tel:${profileData?.phoneNumber?.data?.data}`}
            >
              {profileData?.phoneNumber?.data?.data}
            </Link>
          </FlexColumn>
        </ContactFlexContainer>{" "}
        {profileData?.addressLineOne?.data?.data && (
          <ContactFlexContainer addPadding>
            <IconOuter>
              <IconWrapper>
                <span className="material-icons ico-md">{"location_on"}</span>
              </IconWrapper>
            </IconOuter>
            <FlexColumn>
              <Text primary>Address</Text>{" "}
              <Link
                primary
                lighter
                target="_blank"
                small
                href={`https://www.google.com/maps/search/?api=1${profileData?.addressLineOne?.data?.data},${profileData?.addressLineTwo?.data?.data}`}
              >
                {`${profileData?.addressLineOne?.data?.data} , ${profileData?.addressLineTwo?.data?.data} , ${profileData?.city?.data?.data}, ${profileData?.country?.data?.data}`}
              </Link>
            </FlexColumn>
          </ContactFlexContainer>
        )}
        {profileData?.email?.data?.data && (
          <ContactFlexContainer addPadding>
            <IconOuter>
              <IconWrapper>
                <span className="material-icons ico-md">{"email"}</span>
              </IconWrapper>
            </IconOuter>
            <FlexColumn>
              <Text primary>Email</Text>{" "}
              <Link
                primary
                lighter
                small
                href={`mailto:${profileData?.email?.data?.data}`}
              >
                {profileData?.email?.data?.data}
              </Link>
            </FlexColumn>
          </ContactFlexContainer>
        )}
      </ContactInnerColumnFlex>
    </ContactContainer>
  );
};

export default ContactInfo;

export const IconOuter = styled.div`
  background: linear-gradient(180deg, #2f3a60 0%, #1d2439 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  height: 2.25rem;
  padding: 0.39rem 0.1rem 0.1rem 0.1rem;
  width: 2.5rem;
  min-width: 2.5rem;
`;

export const ContactContainer = styled.div`
  position: absolute;
  top: 50%;
  outline: none;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 25rem;
  z-index: 999;
  height: 23rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ContactInnerColumnFlex = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
  gap: 0.5rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactFlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 0.7rem, 0rem;
`;

const Link = styled.a`
  max-width: 20rem;
  cursor: pointer;
  word-break: break-all;
  text-decoration: underline;
  color: #1696ff;
  font-family: "Poppins", sans-serif;
  font-size: ${(props) => (props.small ? "0.875rem" : "16px")};
  font-weight: ${(props) => (props.lighter ? "400" : "500")};
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const FlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem, 0rem;
`;
