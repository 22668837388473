import "./App.css";
import styled from "styled-components";
import LoadingView from "./components/shared/loading/LoadingView";
import { useState } from "react";
import ProfilePage from "./components/pages/ProfilePage";
import Token from "./components/pages/Token";
import { Header } from "./components/shared/Header/Header";
import Banner from "./components/shared/DashboardComponents/Banner";
import ProductPage from "./components/pages/ProductPage";
import { Error, ErrorContainer } from "./components/pages/Token";
import ErrorBG from "./assets/images/not-found.gif";
import { SubHeading } from "./components/shared/Typography/Typo";
import ObjectSwitch from "./components/pages/ObjectSwitch";
import DocumentPage from "./components/pages/DocumentPage";
import EntityProfilePage from "./components/pages/EntityProfilePage";

function App() {
  const [errorMessage, setError] = useState("Loading");
  const [loading, setLoading] = useState(false);
  const [tokenData, setTokenData] = useState({});

  const params = window.location.pathname.split("/");
  const id = params[2];

  return (
    <Container>
      <Header />
      {tokenData && tokenData.name && <Banner data={tokenData} />}
      {(() => {
        console.log("param : ", params[1]);
        if (loading) {
          return <LoadingView message={errorMessage} />;
        } else if (params[1] === "item") {
          return (
            <Token id={id} setTokenData={setTokenData} tokenData={tokenData} />
          );
        } else if (params[1] === "depro") {
          return (
            <ObjectSwitch
              publicKey={id}
              setTokenData={setTokenData}
              tokenData={tokenData}
            />
          );
        } else if (params[1] === "profile") {
          return <ProfilePage publicKey={id} />;
        } else if (params[1] === "product") {
          return <ProductPage publicKey={id} />;
        } else if (params[1] === "document") {
          return <DocumentPage publicKey={id} />;
        } else if (params[1] === "entity") {
          return <EntityProfilePage publicKey={id} />;
        } else {
          <ErrorContainer style={{ marginTop: "5rem" }}>
            <Error bg={ErrorBG} width="500px">
              <SubHeading primary>{`No Data Found, Try Again`}</SubHeading>
            </Error>
            <Text primary>Looks like you're lost</Text>
          </ErrorContainer>;
        }
      })()}
    </Container>
  );
}

export default App;

const Container = styled.div`
  overflow: auto;
  padding: 0rem 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  @media (max-width: 1368px) {
    margin: 0.6rem;
    gap: 1rem;
    padding: 0rem 0.4rem;
  }
`;

export const IconWrapper = styled.div`
  display: inline-block;
  background: -moz-linear-gradient(top, #00f260 0%, #0575e6 100%);
  background: -webkit-linear-gradient(top, #00f260 0%, #0575e6 100%);
  background: linear-gradient(to bottom, #00f260 0%, #0575e6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00F260, endColorstr=#0575E6, GradientType=0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
