import { Modal } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { CustomButton } from "../fields/Button/CustomButton";
import {
  FlexSpaceBetweenContainer,
  SectionInnerContainer,
} from "../ProductProfile/ProductProfile";
import { SectionContainer } from "../ProductProfile/ProductProfileHeader";
import Verify from "../Profile/Verify";
import { SubHeading } from "../Typography/Typo";

const BlockchainBanner = ({ profileData }) => {
  const [openVerify, setOpenVerify] = useState(false);
  const handleVerifyOpen = () => setOpenVerify(true);
  const handleVerifyClose = () => {
    setOpenVerify(false);
  };

  return (
    <>
      <Modal
        open={openVerify}
        onClose={handleVerifyClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Verify profileData={profileData} />
      </Modal>
      <SectionContainer>
        <SectionInnerContainer>
          <FlexSpaceBetweenContainer>
            <SubHeading primary>Profile Verification</SubHeading>
            <ButtonWrapper>
              <ButtonWrapper>
                <CustomButton
                  login
                  text="Verify"
                  acceptClass
                  onclick={() => {
                    handleVerifyOpen();
                  }}
                />
                <CustomButton login text="Verify on Blockchain" />
              </ButtonWrapper>
            </ButtonWrapper>
          </FlexSpaceBetweenContainer>
        </SectionInnerContainer>
      </SectionContainer>
    </>
  );
};

export default BlockchainBanner;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;
