import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Button, CircularProgress, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";

import { ProductHeader } from "../fields/Header/ProductHeader";
import { Text } from "../Typography/Typo";
import { Gif } from "../DocumentProfile/ViewEditedDetails";
import MultipleSliderView from "../Form/StageForm/MultipleSliderView";
import DecryptGif from "../../../assets/images/decrypt.gif";
import { retrieveTransaction } from "../../../services/api";
import seeBlockchain from "../../../assets/images/seeBlockchain.png";

export const CustomSliderComponent = ({
  item,
  first,
  addPadding,
  setShowAction,
  setVerification,
  isPublic,
  setCurrentField,
  data,
  fieldId,
  encryptedType,
  isDecrypted,
  style,
  fieldName,
  status,
  setEditedDetails,
  setOpenEditedDetails,
  latestTxnHash,
  ipfsHash,
}) => {
  const [netowrk, setNetwork] = useState("");
  const [urlName, setUrlName] = useState("polygonscan");
  const [txnLoading, setTxnLoading] = useState(false);

  useEffect(() => {
    switch (item?.blockchain?.data) {
      case "MUMBAI":
        setUrlName("mumbai.polygonscan");
        break;
      case "ETHEREUM":
        setUrlName("etherscan");
        break;
      case "RINKEBY":
        setUrlName("rinkeby.etherscan");
        break;
      case "BSC":
        setUrlName("bscscan");
        break;
      case "BSCTESTNET":
        setUrlName("testnet.bscscan");
        break;
      case "MATIC":
        setUrlName("polygonscan");
        break;
      default:
        setUrlName("polygonscan");
    }
  }, [item?.blockchain]);

  const positionRef = useRef({
    x: 0,
    y: 0,
  });
  const handleActionOpen = () => {
    setShowAction(true);
  };

  var dataListfieldKeys = Object.keys(item)?.map((data) => {
    return data;
  });

  var customData = Object.keys(item).map((data) => {
    return data;
  });

  const fetchTransactionDetails = async () => {
    setTxnLoading(true);

    try {
      const res = await retrieveTransaction({
        uniqueId: ipfsHash,
      });

      if (res !== undefined) {
        switch (res.data.data[0].network) {
          case "MUMBAI":
            setNetwork("mumbai.polygonscan");
            break;
          case "ETHEREUM":
            setNetwork("etherscan");
            break;
          case "RINKEBY":
            setNetwork("rinkeby.etherscan");
            break;
          case "BSC":
            setNetwork("bscscan");
            break;
          case "BSCTESTNET":
            setNetwork("testnet.bscscan");
            break;
          case "MATIC":
            setNetwork("polygonscan");
            break;
          default:
            setNetwork("polygonscan");
        }
      }
    } catch (error) {
    } finally {
      setTxnLoading(false);
    }
  };

  useEffect(() => {
    latestTxnHash && fetchTransactionDetails();
  }, []);

  const popperRef = useRef(null);
  const areaRef = useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  if (!isDecrypted && item?.isPublic) {
    return (
      <Container>
        <CardContainer
          style={{
            alignItems: "center",
            background: "#fff",
            overflow: "hidden",
            position: "relative",
            minHeight: "16rem",
          }}
        >
          <TextContainer>
            <Text primary>Data Encrypted!</Text>
          </TextContainer>
          <Gif
            src={DecryptGif}
            style={{
              width: "12rem",
              marginTop: "0",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-40%,-40%)",
            }}
          />
          <Button
            style={{
              background: "#1fc36157",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
              padding: "0.3rem 1rem",
              float: "right",
            }}
            onClick={() => {
              var foo = setCurrentField;

              setCurrentField && setCurrentField(fieldId);
            }}
            size="small"
          >
            Decrypt
          </Button>
        </CardContainer>
      </Container>
    );
  } else {
    if (!first) {
      return (
        <Container>
          <TextContainer>
            <HeaderFlexContainer>
              <Text lighter primary>
                {item?.stageName?.data
                  ? item?.stageName?.data
                  : item?.stageName}
              </Text>

              {/* Encryption icon */}
              {isDecrypted && encryptedType === "SYMMETRIC" ? (
                <IconButton>
                  <LockOpenIcon
                    onClick={() => {
                      var foo = setCurrentField;
                      if (typeof foo === "function") {
                        setCurrentField && setCurrentField(fieldId);
                      }
                    }}
                  />
                </IconButton>
              ) : (
                ""
              )}

              {/* Verification icon */}
              <InnerContainer>
                {(() => {
                  if (
                    data?.verifiedBy &&
                    data?.verifiedBy.find(
                      (item) => item?.verificationStatus === 0
                    ) &&
                    isPublic
                  ) {
                    return (
                      <Tooltip
                        placement="top"
                        arrow
                        title="Verification Pending"
                        PopperProps={{
                          popperRef,
                        }}
                      >
                        <StatusContainer
                          style={style}
                          status={0}
                          ref={areaRef}
                          onClick={() => {
                            handleActionOpen();
                            if (setVerification) {
                              setCurrentField && setCurrentField(fieldId);
                              setVerification({
                                type: fieldName,
                                fieldData: data,
                                isPublic: isDecrypted,
                              });
                            }
                          }}
                        >
                          <Box color="#fa7d25fc" />
                        </StatusContainer>
                      </Tooltip>
                    );
                  } else if (isPublic && data?.verifiedBy?.length === 0) {
                    return (
                      <Tooltip
                        arrow
                        placement="top"
                        title="Unverified"
                        PopperProps={{
                          popperRef,
                        }}
                      >
                        <StatusContainer
                          style={style}
                          onClick={() => {
                            handleActionOpen();
                            if (setVerification) {
                              setCurrentField && setCurrentField(fieldId);
                              setVerification({
                                type: fieldName,
                                fieldData: data,
                                isPublic: isDecrypted,
                              });
                            }
                          }}
                          status={status}
                          ref={areaRef}
                          onMouseMove={handleMouseMove}
                        >
                          <Box color="#9b9b9b" />
                        </StatusContainer>
                      </Tooltip>
                    );
                  } else if (
                    data?.verifiedBy &&
                    (data?.verifiedBy.find(
                      (item) => item?.verificationStatus === 1
                    ) ||
                      data?.verifiedBy.length !== 0) &&
                    isPublic
                  ) {
                    return (
                      <Tooltip
                        arrow
                        placement="top"
                        title="Verified"
                        PopperProps={{
                          popperRef,
                        }}
                      >
                        <StatusContainer
                          style={style}
                          onClick={() => {
                            handleActionOpen();
                            if (setVerification) {
                              setCurrentField && setCurrentField(fieldId);
                              setVerification({
                                type: fieldName,
                                fieldData: data,
                                isPublic: isDecrypted,
                              });
                            }
                          }}
                          status={status}
                          ref={areaRef}
                          onMouseMove={handleMouseMove}
                        >
                          <Box color="#59D88C" />
                        </StatusContainer>
                      </Tooltip>
                    );
                  } else if (
                    data?.verifiedBy &&
                    data?.verifiedBy.find(
                      (item) => item?.verificationStatus === 2
                    ) &&
                    isPublic
                  ) {
                    return (
                      <Tooltip
                        arrow
                        placement="top"
                        title="Unverified"
                        PopperProps={{
                          popperRef,
                        }}
                      >
                        <StatusContainer
                          style={style}
                          onClick={() => {
                            handleActionOpen();
                            if (setVerification) {
                              setCurrentField && setCurrentField(fieldId);
                              setVerification({
                                type: fieldName,
                                fieldData: data,
                                isPublic: isDecrypted,
                              });
                            }
                          }}
                          status={status}
                          ref={areaRef}
                          onMouseMove={handleMouseMove}
                        >
                          <Box color="#9b9b9b" />
                        </StatusContainer>
                      </Tooltip>
                    );
                  }
                })()}
                {/* &nbsp; */}
                {/* View edited details */}
                {isPublic && data.editedBy.length > 0 && (
                  <Tooltip
                    arrow
                    placement="top"
                    title="View edited details"
                    PopperProps={{
                      popperRef,
                    }}
                  >
                    <StatusContainer
                      style={style}
                      onClick={() => {
                        setOpenEditedDetails(true);
                        setEditedDetails && setEditedDetails(data.editedBy[0]);
                      }}
                      status={status}
                      ref={areaRef}
                      onMouseMove={handleMouseMove}
                    >
                      <Box color="#0575e6" />
                    </StatusContainer>
                  </Tooltip>
                )}
              </InnerContainer>

              {/* Blockchain icon */}
              {item?.transactionHash && (
                <Tooltip
                  placement="top"
                  arrow
                  title="See Blockchain Transaction"
                  PopperProps={{
                    popperRef,
                    anchorEl: {
                      getBoundingClientRect: () => {
                        return new DOMRect(
                          positionRef.current.x,
                          areaRef.current.getBoundingClientRect().y,
                          0,
                          0
                        );
                      },
                    },
                  }}
                >
                  <StatusContainer
                    style={style}
                    status={0}
                    ref={areaRef}
                    onMouseMove={handleMouseMove}
                  >
                    <Link
                      target="_blank"
                      href={`https://${urlName}.com/tx/${item?.transactionHash?.data}`}
                    >
                      <ArticleTwoToneIcon
                        sx={{ fontSize: 18 }}
                        style={{ color: "#646464" }}
                      />
                    </Link>
                  </StatusContainer>
                </Tooltip>
              )}

              {latestTxnHash &&
                netowrk !== "" &&
                (txnLoading ? (
                  <CircularProgress size={15} />
                ) : (
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#2e8b57",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(`https://${netowrk}.com/tx/${latestTxnHash}`)
                    }
                    src={seeBlockchain}
                    alt={"pdf"}
                    loading="lazy"
                  />
                ))}
            </HeaderFlexContainer>
          </TextContainer>
          <ArrowContainer>
            <ArrowBody>
              <ArrowHead>
                <ArrowSpan />
              </ArrowHead>
            </ArrowBody>
          </ArrowContainer>
          <CardContainer>
            {Object.keys(item)?.map((fieldName, key) => {
              if (item[fieldName]?.data?.toString()?.includes("ipfs")) {
                return (
                  <MultipleSliderView
                    key={key}
                    data={item[fieldName]?.data?.toString()?.split(",")}
                    fieldName={fieldName}
                  />
                );
              }
              if (fieldName !== "stageName") {
                return (
                  <FieldWrapper key={key}>
                    <Text
                      primary
                      lighter
                      small
                      style={{ justifySelf: "start" }}
                    >
                      {fieldName
                        .toString()
                        .replace(/([A-Z])/g, " $1")
                        .substring(0, 1)
                        .toUpperCase() +
                        fieldName
                          .toString()
                          .replace(/([A-Z])/g, " $1")
                          .substring(1)}
                    </Text>
                    <Text
                      primary
                      lighter
                      small
                      style={{ justifySelf: "end", wordBreak: "break-word" }}
                    >
                      {item[fieldName]?.data}
                    </Text>
                  </FieldWrapper>
                );
              }
            })}
          </CardContainer>
        </Container>
      );
    } else {
      return (
        <Container>
          <TextContainer>
            <HeaderFlexContainer>
              <Text primary>
                {item?.addedDate?.data
                  ? item?.addedDate?.data
                  : item?.addedDate}
              </Text>
              <IconButton>
                <LockOpenIcon />
              </IconButton>
            </HeaderFlexContainer>
          </TextContainer>
          <ArrowContainer>
            <ArrowBody>
              <ArrowHead>
                <ArrowSpan />
              </ArrowHead>
            </ArrowBody>
          </ArrowContainer>
          <CardContainer>
            <ProductHeader
              addPadding={addPadding}
              productName={
                item?.stageName?.data ? item?.stageName?.data : item?.stageName
              }
              id={
                item?.id?.data
                  ? item?.id?.data.substring(0, 11 - 3)
                  : item?.data.substring(0, 11 - 3)
              }
              description={
                item.stageDescription?.data
                  ? item?.stageDescription?.data
                  : item?.stageDescription
              }
            />
            {/* {dataListfieldKeys &&
              dataListfieldKeys.length > 0 &&
              dataListfieldKeys[0].map((fieldName, key) => {
                return (
                  <FieldWrapper key={key}>
                    <Text
                      primary
                      lighter
                      small
                      style={{ justifySelf: "start" }}
                    >
                      {fieldName
                        .toString()
                        .replace(/([A-Z])/g, " $1")
                        .substring(0, 1)
                        .toUpperCase() +
                        fieldName
                          .toString()
                          .replace(/([A-Z])/g, " $1")
                          .substring(1)}
                    </Text>
                    <Text
                      primary
                      lighter
                      small
                      style={{ justifySelf: "end", wordBreak: "break-word" }}
                    >
                      {customData[0][fieldName]}
                    </Text>
                  </FieldWrapper>
                );
              })} */}
            {/* <FieldWrapper addPadding={addPadding}>
              <Text primary lighter small style={{ justifySelf: "start" }}>
                Transaction Hash
              </Text>
              <HyperLink
                primary
                lighter
                small
                target="_blank"
                href={`https://laboratory.stellar.org/#explorer?resource=operations&endpoint=for_transaction&values=${encodeURIComponent(
                  btoa(
                    JSON.stringify({
                      transaction: data.txnHash,
                    })
                  )
                )}&network=test`}
                style={{ justifySelf: "end", wordBreak: "break-word" }}
              >
                {data?.txnHash}
              </HyperLink>
            </FieldWrapper> */}
          </CardContainer>
        </Container>
      );
    }
  }
};

const Container = styled.div`
  flex-direction: column;
  position: relative;
  display: flex;
  // min-width: 20rem;
`;

const CardContainer = styled.div`
  flex-direction: column;
  display: flex;
  z-index: 30;
  justify-content: space-between;
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  gap: 0.5rem;
  padding: ${(props) => (props.addPadding ? "1.5rem" : "1rem")};
`;

const ArrowContainer = styled.div`
  height: 5rem;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ArrowBody = styled.div`
  width: 0.5rem;
  position: relative;
  height: 100%;
  background-color: #c3d0e1;
  margin-top: 0.5rem;
`;

const ArrowHead = styled.div`
  width: 1.5rem;
  border-radius: 50px;
  display: grid;
  place-items: center;
  position: absolute;
  top: -10%;
  left: -100%;
  height: 1.5rem;
  background-color: #c3d0e1;
`;

const ArrowSpan = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50px;
  background-color: #080036;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 1rem;
`;

export const HyperLink = styled.a`
  font-size: ${(props) => (props.small ? "0.875rem" : "1.125rem")};
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const FieldWrapper = styled.div`
  padding: ${(props) => (props.addPadding ? "1rem 1rem" : "0.2rem 1rem")};
  background-color: #c3d0e1;
  display: grid;
  border-radius: 5px;
  grid-template-columns: 1fr 1fr;
`;

const Image = styled.img`
  width: 4rem;
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  justify-self: end;
  border-radius: 5px;
  text-align: center;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;

const HeaderFlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const IconContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 0.2rem;
  cursor: pointer;
`;

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  gap: 0.7rem;
  justify-content: center;
  align-items: center;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: ${(props) => props.status !== 1 && "pointer"};
`;

export const Box = styled.div`
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

export const Link = styled.a`
  color: #1e1b3b;
  max-width: 600px;
  word-break: break-word;
  font-weight: 500;
  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
  }
`;
