import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/css/index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      {" "}
      <SnackbarProvider
        classes={{
          containerAnchorOriginTopCenter: "z-alert",
        }}
        maxSnack={1}
        preventDuplicate
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <App />
      </SnackbarProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
