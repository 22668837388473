import axios from "axios";

export const GetIPFSData = async (ipfsHash) => {
  try {
    const res = await axios.get(
      `https://niftron.infura-ipfs.io/ipfs/${ipfsHash}`
    );
    if (res) {
      return res.data;
    }
  } catch (er) {
    console.log(er);
    return null;
  }
};

export async function retrieveItem(data) {
  try {
    const response = await axios.get(
      `https://lsfa4pasgk.execute-api.us-east-1.amazonaws.com/stage/${data.uniqueId}/item`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveTransaction(data) {
  try {
    const response = await axios.get(
      `https://smt2h2y30f.execute-api.us-east-1.amazonaws.com/stage/${data.uniqueId}/transaction`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveALL(data) {
  try {
    const response = await axios.get(
      "https://smt2h2y30f.execute-api.us-east-1.amazonaws.com/stage/object/" +
        data.query.id
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveWorkflow(data) {
  try {
    const response = await axios.get(
      `https://jog2ao3i93.execute-api.us-east-1.amazonaws.com/stage/${data.path.id}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function me(data) {
  try {
    const response = await axios.get(
      "https://smt2h2y30f.execute-api.us-east-1.amazonaws.com/stage/users/" +
        data.path.id
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
