import React from "react";
import styled from "styled-components";

export const LoginIcon = (props) => {
  if (props.large) {
    return (
      <LargeContainer>
        <Icon
          src="https://niftron-logo.s3.amazonaws.com/NIFTRON+LOGO+SINGLE+LIGHT"
          alt="logo"
        />
      </LargeContainer>
    );
  } else {
    return (
      <Container>
        <Icon
          src="https://niftron-logo.s3.amazonaws.com/NIFTRON+LOGO+SINGLE+LIGHT"
          alt="logo"
        />
      </Container>
    );
  }
};

const Icon = styled.img`
  width: 100%;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
`;

const Container = styled.div`
  position: relative;
  width: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  height: 1.5rem;
  background: #edf1f4;
  border-radius: 50%;
`;

const LargeContainer = styled.div`
  position: relative;
  width: 3rem;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  height: 3rem;
  background: #edf1f4;
  border-radius: 50%;
`;
