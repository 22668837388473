import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Text } from "../Typography/Typo";

const EncryptedData = ({ fieldData, setShowDecryption }) => {
  const matches = useMediaQuery("(max-width:1368px)");
  const Hash = "*";
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    if (matches) {
      if (fieldData && fieldData.length < 50) setLimit(10);
      else if (fieldData && fieldData.length > 50 && fieldData.length < 70)
        setLimit(15);
      else if (fieldData && fieldData.length > 80) setLimit(10);
      else if (fieldData && fieldData.length > 100) setLimit(null);
    } else {
      if (fieldData && fieldData.length < 50) setLimit(20);
      else if (fieldData && fieldData.length > 50 && fieldData.length < 70)
        setLimit(35);
      else if (fieldData && fieldData.length > 80) setLimit(20);
      else if (fieldData && fieldData.length > 100) setLimit(null);
    }
  }, [matches]);

  return (
    <Container
      cursor={setShowDecryption ? true : false}
      onClick={() => {
        setShowDecryption && setShowDecryption(true);
      }}
    >
      <Text small color="#7e7e7e">
        {limit === null
          ? Hash.repeat(fieldData && fieldData.length)
          : Hash.repeat(
              fieldData && fieldData.toString().substring(0, limit - 3).length
            )}
      </Text>
    </Container>
  );
};

export default EncryptedData;

const Container = styled.div`
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
`;
