import React from "react";
import styled from "styled-components";
import Logo from "../../../assets/images/SingleLogo.svg";

function InnerLoadingView({ style }) {
  return (
    <Container style={style}>
      <div>
        <span>
          <span></span>
        </span>
        <span>
          <span></span>
        </span>
      </div>
      <div className="loader">
        {/*?xml version="1.0" encoding="utf-8"?*/}
        {/* Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  */}
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 250 250"
          style={{ enableBackground: "new 0 0 250 250" }}
          xmlSpace="preserve"
        >
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: "\n\t.myColor{fill: #1E1B3B;}\n",
            }}
          />
          <path
            className="myColor"
            d="M20,100c0,44.2,35.8,80,80,80s80-35.8,80-80c0,46.4-35.8,84-80,84S20,146.4,20,100"
          >
            <animateTransform
              accumulate="none"
              additive="replace"
              attributeName="transform"
              begin="0s"
              calcMode="linear"
              dur="2s"
              fill="remove"
              keyTimes="0;1"
              repeatCount={10}
              restart="always"
              type="rotate"
              values="0 100 100;360 100 100"
            ></animateTransform>
          </path>
        </svg>
        <img className="logo flash" src={Logo} alt="loading" />
      </div>
    </Container>
  );
}

export default InnerLoadingView;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 30vh;
`;
