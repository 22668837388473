import React, { Fragment, useEffect, useState } from "react";
import { GetIPFSData, me } from "../../services/api.js";
import { urlToHash } from "../../services/ipfs";
import InnerLoadingView from "../shared/loading/InnerLoadingView.js";
import UserProfile from "../shared/Profile/UserProfile";
import { SubHeading, Text } from "../shared/Typography/Typo.js";
import { Error, ErrorContainer } from "./Token.js";
import ErrorBG from "../../assets/images/not-found.gif";
import ProviderView from "../shared/ProviderProfile/ProviderView.js";
import EntityProfile from "../shared/EntityProfile/EntityProfile.js";

const EntityProfilePage = ({ publicKey }) => {
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [meData, setMeData] = useState();
  const [loading, setLoading] = useState(false);

  const fetchLatestEntityProfileData = async () => {
    setLoading(true);
    try {
      const meData = await me({
        path: {
          id: publicKey,
        },
      });

      if (meData && meData?.data?.data?.length > 0) {
        setMeData(meData?.data?.data[0]);
        const hash = urlToHash(meData?.data?.data[0]?.latestIpfsUrl?.ipfsUrl);
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLatestEntityProfileData();
  }, []);

  return (
    <Fragment>
      {(() => {
        if (loading) {
          return <InnerLoadingView style={{ marginTop: "15rem" }} />;
        } else if (meData?.type === "PROFILE" && meData?.profileType === 2) {
          return <EntityProfile profileData={latestIpfsData} />;
        } else if (meData?.type === "CREDENTIALPROVIDER") {
          return <ProviderView profileData={latestIpfsData} />;
        } else {
          return (
            <ErrorContainer style={{ marginTop: "5rem" }}>
              <Error bg={ErrorBG} width="500px">
                <SubHeading primary>{`Profile Not Found!`}</SubHeading>
              </Error>
              <Text primary>Looks like you're lost</Text>
            </ErrorContainer>
          );
        }
      })()}
    </Fragment>
  );
};

export default EntityProfilePage;
