import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { IconButton, Modal } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { Heading } from "../Typography/Typo";
import { FieldWrapper } from "../ProductProfile/ProductProfile";
import VerifiedStatusContainer from "../Profile/VerifiedStatusContainer";

const DocumentProfileHeader = ({
  profileData,
  isPublic,
  setCurrentField,
  // openEditedDetails,
  // editedDetails,
  latestTxnHash,
  setVerification,
  setShowAction,
  ipfsHash,
}) => {
  return (
    <Fragment>
      <SectionContainer>
        <Banner>
          <SectionInnerContainer>
            <ContentContainer>
              <FieldWrapper>
                <Heading
                  primary
                >{`${profileData?.documentName?.data?.data}`}</Heading>
                <VerifiedStatusContainer
                  isPublic={isPublic}
                  fieldData={profileData?.documentName}
                  fieldName="Document Name"
                  fieldId={{ id: "documentName" }}
                  setCurrentField={setCurrentField}
                  // openEditedDetails={openEditedDetails}
                  // editedDetails={editedDetails}
                  latestTxnHash={latestTxnHash}
                  setVerification={setVerification}
                  setShowAction={setShowAction}
                  isDecrypted={true}
                  ipfsHash={ipfsHash}
                />
              </FieldWrapper>
            </ContentContainer>
          </SectionInnerContainer>
        </Banner>
      </SectionContainer>
    </Fragment>
  );
};

export default DocumentProfileHeader;

export const SectionContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border-radius: 10px;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  margin-top: 3rem;
  margin-left: 2rem;
  @media (max-width: 1368px) {
    margin-top: 3rem;
    margin-left: 1rem;
    width: 8rem;
    height: 8rem;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const Banner = styled.div`
  position: relative;
  background: linear-gradient(45deg, #0575e6 1%, #00f260 100%);
  height: 10rem;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1368px) {
    height: 8rem;
  }
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 4%;
  right: 1.5%;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
`;
export const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;
export const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 60vh;
  overflow: hidden;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
