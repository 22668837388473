import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Text } from "../Typography/Typo";
import VerifiedStatusContainer from "./VerifiedStatusContainer";
import PropTypes from "prop-types";
import { urlToHash } from "../../../services/ipfs";

const ExperienceCard = ({
  title,
  institution,
  startDate,
  endDate,
  description,
  logoImage,
  isPublic,
  status,
  fieldData,
}) => {
  console.log(fieldData);
  return (
    <Container>
      <SmallImageWrapper>
        <ProfileImage
          src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(logoImage)}`}
          alt={title}
        />
      </SmallImageWrapper>
      <Content>
        <FlexSpaceContainer>
          <Text primary>{title}</Text>
          <VerifiedStatusContainer
            status={status}
            isPublic={isPublic}
            fieldData={fieldData}
            fieldName={
              fieldData?.data?.position ? "Work Experience" : "Education"
            }
          />
        </FlexSpaceContainer>
        <Text primary lighter>
          {institution}
        </Text>
        <Text lighter color="#6d6d6d">
          {`${moment(Number(startDate)).format("MMM YYYY")} - ${
            endDate === "undefined"
              ? "Present"
              : endDate > Date.now()
              ? "Present"
              : moment(Number(endDate)).format("MMM YYYY")
          }`}
        </Text>
        {description && (
          <Text style={{ marginTop: "0.5rem" }} primary lighter>
            {description}
          </Text>
        )}
      </Content>
    </Container>
  );
};

ExperienceCard.propTypes = {
  status: PropTypes.number,
  isPublic: PropTypes.bool,
  title: PropTypes.string,
  institution: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  description: PropTypes.string,
  logoImage: PropTypes.any,
  fieldData: PropTypes.object,
};

export default ExperienceCard;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  margin: 0.5rem 0rem;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SmallImageWrapper = styled.div`
  overflow: hidden;
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const FlexSpaceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
