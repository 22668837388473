import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Modal } from "@mui/material";
import { ImageFlexContainer, LargeImage } from "../Profile/UserProfileHeader";
import VerifiedStatusContainer from "../Profile/VerifiedStatusContainer";
import { Heading, Text } from "../Typography/Typo";
import ContactInfo from "../Profile/ContactInfo";
import { FieldWrapper } from "../Profile/UserProfile";
import { urlToHash } from "../../../services/ipfs";

const ProviderProfileHeader = ({ profileData, isPublic }) => {
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };

  const [openContact, setContactOpen] = useState(false);
  const handleContactOpen = () => setContactOpen(true);
  const handleContactClose = () => {
    setContactOpen(false);
  };
  return (
    <Fragment>
      <Modal
        open={openContact}
        onClose={handleContactClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactInfo profileData={profileData} />
      </Modal>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage
              src={
                profileData?.profileImage?.data?.data === "undefined"
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : !profileData?.profileImage?.data?.data
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(profileData?.profileImage?.data?.data)}`
              }
              alt="image"
            />
          </div>
        </ImageFlexContainer>
      </Modal>
      <SectionContainer>
        <Banner>
          <ImageWrapper>
            <ProfileImage
              alt="Profile Picture"
              cursor
              src={
                profileData?.profileImage?.data?.data === "undefined"
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : !profileData?.profileImage?.data?.data
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(profileData?.profileImage?.data?.data)}`
              }
              onClick={() => {
                handleImageOpen();
              }}
            />
          </ImageWrapper>
        </Banner>
        <SectionInnerContainer>
          <ContentContainer>
            <FieldWrapper>
              <Heading primary>{profileData?.name?.data?.data}</Heading>
              <VerifiedStatusContainer status={0} isPublic={isPublic} />
            </FieldWrapper>

            <FieldWrapper>
              <Text lighter color="#6d6d6d">
                {`${profileData?.city?.data?.data} ${profileData?.country?.data?.data}`}
              </Text>
              <VerifiedStatusContainer status={0} isPublic={isPublic} />
            </FieldWrapper>
            <FieldWrapper>
              <SpanLink onClick={handleContactOpen}>Contact Info</SpanLink>
            </FieldWrapper>
          </ContentContainer>
        </SectionInnerContainer>
      </SectionContainer>
    </Fragment>
  );
};

export default ProviderProfileHeader;

export const SectionContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border-radius: 10px;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  margin-top: 3rem;
  margin-left: 2rem;
  @media (max-width: 1368px) {
    margin-top: 3rem;
    margin-left: 1rem;
    width: 8rem;
    height: 8rem;
  }
`;

const SpanLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #1696ff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const Banner = styled.div`
  position: relative;
  background: linear-gradient(45deg, #0575e6 1%, #00f260 100%);
  height: 10rem;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1368px) {
    height: 8rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const SmallImageWrapper = styled.div`
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 4%;
  right: 1.5%;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
`;
