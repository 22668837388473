import React, { Fragment, useState } from "react";
import styled from "styled-components";
import {
  ArrowBody,
  ArrowsContainer,
  Slider,
} from "../Form/StageForm/StageSliderView";
import VerifiedStatusContainer from "../Profile/VerifiedStatusContainer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { SubHeading, Text } from "../Typography/Typo";
import ProductProfileHeader, { SectionContainer } from "./ProductProfileHeader";
import AdditionalDetails from "../Profile/AdditionalDetails";
import BlockchainBanner from "../DashboardComponents/BlockchainBanner";
import { ViewVerifiedDetails } from "./ViewVerifiedDetails";
import VerificationStatusView from "../VerificationView/VerificationStatusView";

const ProductProfile = ({
  latestTxnHash,
  profileData,
  ipfsHash,
  profileType,
}) => {
  const [isPublic, setIsPublic] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [viewVerifiedData, setViewVerifiedData] = useState(false);
  const [currentField, setCurrentField] = useState({});
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => setImageOpen(false);
  const [imageData, setImageData] = useState({});
  const [openEditedDetails, setOpenEditedDetails] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [verification, setVerification] = useState({
    type: null,
    fieldData: null,
  });

  return (
    <Fragment>
      {showAction && (
        <VerificationStatusView
          verification={verification}
          setShowAction={setShowAction}
        />
      )}
      {viewVerifiedData && (
        <ViewVerifiedDetails
          verifiedData={currentField}
          setShowAction={setViewVerifiedData}
        />
      )}
      <Container>
        <ProductProfileHeader
          profileData={profileData}
          isPublic={true}
          setIsPublic={setIsPublic}
          setCurrentField={setCurrentField}
          // openEditedDetails={openEditedDetails}
          // editedDetails={editedDetails}
          latestTxnHash={latestTxnHash}
          ipfsHash={ipfsHash}
          setVerification={setVerification}
          setShowAction={setShowAction}
        />
        {/* <BlockchainBanner profileData={profileData} /> */}
        <SectionContainer>
          <SectionInnerContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>About</SubHeading>
              <VerifiedStatusContainer
                isPublic={true}
                fieldData={profileData?.description}
                fieldName="Description"
                fieldId={{ id: "description" }}
                setCurrentField={setCurrentField}
                setOpenEditedDetails={setOpenEditedDetails}
                setEditedDetails={setEditedDetails}
                latestTxnHash={latestTxnHash}
                setVerification={setVerification}
                setShowAction={setShowAction}
                isDecrypted={true}
                ipfsHash={ipfsHash}
              />
            </FlexSpaceBetweenContainer>
            <Text primary lighter>
              {profileData?.description?.data?.data}
            </Text>
          </SectionInnerContainer>
        </SectionContainer>

        <SectionContainer>
          <FlexColumnContainer>
            <SubHeading primary>Stages</SubHeading>
            {/* <Slider
              productData={profileData?.stageData}
              setViewVerifiedData={setViewVerifiedData}
              setCurrentField={setCurrentField}
              isPublic={isPublic}
            />{" "} */}
            <Slider
              productData={profileData?.stageData}
              setShowAction={setShowAction}
              setVerification={setVerification}
              setViewVerifiedData={setViewVerifiedData}
              setCurrentField={setCurrentField}
              // setShowDecryption={setShowDecryption}
              // setShowEncryption={encryptData}
              latestTxnHash={latestTxnHash}
              isDecrypted={true}
              ipfsHash={ipfsHash}
              isPublic={true}
            />
            {profileData?.stageData.length > 0 && (
              <ArrowsContainer>
                <ArrowBody className="prev">
                  <ArrowBackIosNewIcon
                    className="prev"
                    style={{ color: "#080036", cursor: "pointer" }}
                  />
                </ArrowBody>
                <ArrowBody className="next">
                  <ArrowForwardIosIcon
                    className="next"
                    style={{ color: "#080036", cursor: "pointer" }}
                  />
                </ArrowBody>
              </ArrowsContainer>
            )}
          </FlexColumnContainer>
        </SectionContainer>
        <AdditionalDetails
          isPublic={true}
          profileData={profileData}
          setImageData={setImageData}
          handleImageOpen={handleImageOpen}
          setCurrentField={setCurrentField}
          latestTxnHash={latestTxnHash}
          setVerification={setVerification}
          setShowAction={setShowAction}
          ipfsHash={ipfsHash}
          profileType={profileType}
        />
      </Container>
    </Fragment>
  );
};

export default ProductProfile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1.5rem 1.5rem 3rem 1.5rem;
`;
