import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import ErrorBG from "../../assets/images/not-found.gif";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import { retrieveItem } from "../../services/api";
import Content from "./Content";

const Token = ({ id, tokenData, setTokenData }) => {
  const [loading, setLoading] = useState(false);

  // Retrieving item API
  const retrieveItems = async () => {
    setLoading(true);
    try {
      const response = await retrieveItem({ uniqueId: id });
      if (response.data.code === 200) {
        setTokenData(response.data.data[0]);
        console.log(response.data.data[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    retrieveItems();
  }, []);

  return (
    <Container>
      {(() => {
        if (loading) {
          return <InnerLoadingView style={{ marginTop: "10rem" }} />;
        } else if (!tokenData?.uniqueId) {
          return (
            <ErrorContainer>
              <Error bg={ErrorBG} width="600px">
                <SubHeading
                  primary
                >{`Certificate / Token Not Found!`}</SubHeading>
              </Error>
              <Text primary>Looks like you're lost</Text>
            </ErrorContainer>
          );
        } else {
          return <Content tokenData={tokenData} />;
        }
      })()}
    </Container>
  );
};

export default Token;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
  min-height: 70vh;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    margin-bottom: 1rem;
    padding: 0.5rem;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
`;

export const Error = styled.div`
  background-image: url(${(props) => props.bg});
  height: ${(props) => props.width};
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
`;

export const ErrorContainer = styled.div`
  display: flex;
  height: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DisabledView = styled.div`
  display: flex;
  color: #2c2858;
  padding: 0.5rem;
  background-color: #eff2f5;
  border-radius: 5px;
  align-items: center;
  word-break: break-all;
`;
