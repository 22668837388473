import React, { Fragment, useEffect, useState } from "react";
import { GetIPFSData, me } from "../../services/api.js";
import { urlToHash } from "../../services/ipfs";
import InnerLoadingView from "../shared/loading/InnerLoadingView.js";
import { SubHeading, Text } from "../shared/Typography/Typo.js";
import { Error, ErrorContainer } from "./Token.js";
import ErrorBG from "../../assets/images/not-found.gif";
import DocumentProfile from "../shared/DocumentProfile/DocumentProfile.js";

const DocumentPage = ({ publicKey }) => {
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [loading, setLoading] = useState(false);
  const [latestTxnHash, setLatestTxnHash] = useState("");

  const [documentData, setDocumentData] = useState({});

  const fetchLatestProfileData = async () => {
    setLoading(true);
    try {
      const meDataRes = await me({
        path: {
          id: publicKey,
        },
      });

      console.log("meDataRes : ", meDataRes);

      if (meDataRes && meDataRes?.data?.data?.length > 0) {
        const hash = urlToHash(
          meDataRes?.data?.data[0]?.latestIpfsUrl?.ipfsUrl
        );
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
        setDocumentData(meDataRes.data.data[0]);
        setLatestTxnHash(meDataRes?.data?.data[0]?.latestIpfsUrl?.txnHash);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLatestProfileData();
  }, []);

  return (
    <Fragment>
      {(() => {
        if (loading) {
          return <InnerLoadingView style={{ marginTop: "15rem" }} />;
        } else if (documentData.profileType === 4) {
          return (
            <DocumentProfile
              profileData={latestIpfsData}
              latestTxnHash={latestTxnHash}
            />
          );
        } else {
          return (
            <ErrorContainer style={{ marginTop: "5rem" }}>
              <Error bg={ErrorBG} width="500px">
                <SubHeading primary>{`Document Not Found!`}</SubHeading>
              </Error>
              <Text primary>Looks like you're lost</Text>
            </ErrorContainer>
          );
        }
      })()}
    </Fragment>
  );
};

export default DocumentPage;
