import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { SubHeading, Text } from "../Typography/Typo";
import WorkSection from "./WorkSection";
import UserProfileHeader, { SectionContainer } from "./UserProfileHeader";
import VerifiedStatusContainer from "./VerifiedStatusContainer";
import EducationSection from "./EducationSection";
import AchievementSection from "./AchievementSection";
import AdditionalDetails from "./AdditionalDetails";

const UserProfile = ({ profileData }) => {
  const [isPublic, setIsPublic] = useState(true);

  // console.log(profileData);

  return (
    <Fragment>
      <Container>
        <UserProfileHeader
          profileData={profileData}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
        />
        <SectionContainer>
          <SectionInnerContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>About</SubHeading>{" "}
              <VerifiedStatusContainer
                status={0}
                isPublic={isPublic}
                fieldData={profileData?.bio?.data?.data}
                fieldName="Bio"
              />
            </FlexSpaceBetweenContainer>
            <Text primary lighter>
              {profileData?.bio?.data?.data}
            </Text>
          </SectionInnerContainer>
        </SectionContainer>
        {/* Work Section */}
        <WorkSection isPublic={isPublic} profileData={profileData} />
        {/* Education Section */}
        <EducationSection isPublic={isPublic} profileData={profileData} />
        {/*AchievementSection */}
        <AchievementSection isPublic={isPublic} profileData={profileData} />
        <AdditionalDetails isPublic={isPublic} profileData={profileData} />
      </Container>
    </Fragment>
  );
};

export default UserProfile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
