import { Divider } from "@mui/material";
import React, { Fragment } from "react";
import { SubHeading } from "../Typography/Typo";
import TokenCard from "./TokenCard";
import { SectionInnerContainer } from "./UserProfile";
import { SectionContainer } from "./UserProfileHeader";

function AchievementSection({
  isPublic,
  profileData,
}) {
  return (
    <SectionContainer>
      <SectionInnerContainer>
        <SubHeading primary>Achievements</SubHeading>{" "}
        {profileData?.achievements &&
          profileData?.achievements?.map((item, key) => {
            console.log(item);
            return (
              <Fragment key={key}>
                <TokenCard
                  fieldData={item}
                  itemName={item?.data?.itemName?.data}
                  itemImage={item?.data?.itemImage?.data}
                  status={0}
                  isPublic={isPublic}
                  issueDate={item?.data?.issueDate?.data}
                  issuer={item?.data?.alias?.data}
                  description={item?.data?.description?.data}
                />
                {profileData?.achievements?.length > 1 &&
                  profileData?.achievements?.length - 1 != key && (
                    <Divider
                      style={{
                        background: "#5e5e5e11",
                        width: "100%",
                      }}
                    />
                  )}
              </Fragment>
            );
          })}
      </SectionInnerContainer>
    </SectionContainer>
  );
}

export default AchievementSection;
