import styled from "styled-components";
import { Text } from "../Typography/Typo";
import moment from "moment";
import { CustomButton } from "../fields/Button/CustomButton";

const Avatar = ({ data }) => {
  return (
    <Container>
      <SpaceContainer>
        <FlexContainer>
          <AvatarComponent>
            <svg
              style={{ color: "#9b9b9b", width: "50%", height: "50%" }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="user"
              className="svg-inline--fa fa-user fa-w-14 cr-badge-banner-image__icon"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M313.6 288c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zM416 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 56.5 0 102.4 45.9 102.4 102.4V464zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"
              ></path>
            </svg>
          </AvatarComponent>

          <Text lighter color="#11093E" style={{ width: "100%" }}>
            This badge was issued to&nbsp;
            <Link
              href={
                data?.profilePublicKey !== undefined &&
                `/profile/${data?.profilePublicKey}`
              }
            >{`${data?.name} `}</Link>
            {`in ${moment(data?.date).format("MMM YYYY")}`}
          </Text>
        </FlexContainer>

        {/* <FlexContainer>
          {data?.status === 0 && !data.candidatePublicKey && (
            <CustomButton
              login
              icon={claimSubmitLoading && <CircularProgress size={25} />}
              disabled={claimSubmitLoading}
              text={
                tokenData?.type === "education"
                  ? "Claim Certificate"
                  : "Claim Token"
              }
              acceptClass
              onclick={() => {
                setShowAction(true);
              }}
            />
          )}
        </FlexContainer> */}
      </SpaceContainer>
    </Container>
  );
};

export default Avatar;

const Container = styled.div`
  background-color: #e7e7e7;
  border: 0.75px solid #d3d3d36e;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  @media (max-width: 1568px) {
    padding: 2rem;
  }
  @media (max-width: 768px) {
    margin-bottom: 0rem;
  }
`;

const AvatarComponent = styled.div`
  border-radius: 50%;
  border: 1px solid #c2c2c2;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 88px;
  width: 88px;
  height: 88px;
  min-height: 88px;
  @media (max-width: 768px) {
    min-width: 58px;
    width: 58px;
    min-height: 58px;
    height: 58px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
  @media (max-width: 768px) {
    align-items: unset;
  }
`;

const SpaceContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const PseudoButton = styled.div`
  background: transparent;
  cursor: pointer;
  text-decoration: underline;
  outline: none;
  border: 0;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Link = styled.a`
  color: #1e1b3b;
  max-width: 600px;
  word-break: break-word;
  font-weight: 500;
  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
  }
`;
