import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import moment from "moment";
import Divider from "@mui/material/Divider";
import { IconButton } from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Heading, Text } from "../Typography/Typo";

export const ViewEditedDetails = ({ setShowAction, editedDetails }) => {
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = async (val) => {
    try {
      await navigator.clipboard.writeText(val);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <OverLayContainer
        onClick={() => {
          setShowAction(false);
        }}
      />

      <Container>
        <FlexContainer>
          <Text primary>Modified Details</Text>
        </FlexContainer>
        <Divider
          style={{
            background: "#36353511",
            width: "100%",
          }}
        />
        <Content>
          <Text primary>Editor Alias</Text>
          <Text primary>{editedDetails.editorAlias}</Text>
          <Text primary>Editor Public Key</Text>

          <FlexSpaceBetweenContainer>
            <Span primary>
              {editedDetails.editorPublicKey?.toString()?.substring(0, 25 - 3) +
                "..."}
            </Span>
            <IconButton
              onClick={() => {
                copyToClipBoard(editedDetails.editorPublicKey);
                enqueueSnackbar("URL Copied", {
                  variant: "info",
                });
              }}
            >
              <ContentCopyIcon
                style={{
                  color: "#6d6d6d",
                  fontSize: "20px",
                }}
              />
            </IconButton>
          </FlexSpaceBetweenContainer>
          <Text primary>Edited Date</Text>
          <Text primary>{moment(editedDetails?.createdAt).format("LLL")}</Text>
        </Content>
      </Container>
    </Fragment>
  );
};

export const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 40%;
  z-index: 999;
  height: auto;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
    padding: 1.5rem;
  }
  @media (max-width: 1368px) {
    width: 80%;
  }
`;

const Span = styled.span`
  font-weight: 500;
  max-width: 400px;
  word-break: break-word;
  color: #6d6d6d;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};

  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
    font-size: 14px;
  }
`;

const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  margin-top: 20px;
  display: grid;
  gap: 0.5rem;
  width: 80%;
  grid-template-columns: 2fr 3fr;
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

export const SmallText = styled.p`
  font-size: 0.7rem;
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
`;

export const ColumnLoadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  height: 20rem;
  align-items: center;
`;

export const Gif = styled.img`
  margin-top: 5rem;
  width: 16rem;
  height: auto;
`;

const FlexContainer = styled.div`
  width: 93%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
`;
