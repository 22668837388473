import styled from "styled-components";
import { Link } from "../shared/DashboardComponents/Banner";
import { LargeHeading, SubHeading, Text } from "../shared/Typography/Typo";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Modal, useMediaQuery } from "@mui/material";
import {urlToHash} from "../../services/ipfs"
const Content = ({ tokenData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [urlName, setUrlName] = useState("polygonscan");
  const [blockchain, setBlockchain] = useState("MATIC");
  const matches = useMediaQuery("(max-width:768px)");
  const [maxLength, setMaxLength] = useState(100);
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };
  // console.log(tokenData);

  useEffect(() => {
    switch (tokenData?.networkId) {
      case 1:
        setUrlName("etherscan");
        setBlockchain("ETHEREUM");
        break;
      case 4:
        setUrlName("rinkeby.etherscan");
        setBlockchain("RINKEBY");
        break;
      case 56:
        setUrlName("bscscan");
        setBlockchain("BSC");
        break;
      case 97:
        setUrlName("testnet.bscscan");
        setBlockchain("BSCTESTNET");
        break;
      case 137:
        setUrlName("polygonscan");
        setBlockchain("MATIC");
        break;
      case 80001:
        setUrlName("mumbai.polygonscan");
        setBlockchain("MUMBAI");
        break;
      default:
        setUrlName("polygonscan");
        setBlockchain("MATIC");
    }
  }, [tokenData?.networkId]);

  const copyToClipBoard = async (val) => {
    try {
      await navigator.clipboard.writeText(val);
      enqueueSnackbar("Key Copied", {
        variant: "info",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(tokenData?.itemImage)}`} alt="image" />
          </div>
        </ImageFlexContainer>
      </Modal>
      <FlexContainer>
        {matches && (
          <MainContentColumn>
            <div>
              <LargeHeading primary>{tokenData?.itemName}</LargeHeading>
              <Text lighter>
                Issued by&nbsp;
                <Link href={`/profile/${tokenData?.senderPublicKey}`}>
                  {tokenData?.alias}
                </Link>
              </Text>
            </div>
            <Text primary lighter>
              {tokenData?.description}
            </Text>
          </MainContentColumn>
        )}
        <TopColumn>
          <LargeContainer onClick={handleImageOpen}>
            <AbsoluteHover>
              {tokenData?.type === "education"
                ? "View Certificate"
                : "View Token"}
            </AbsoluteHover>
            <Icon src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(tokenData?.itemImage)}`} alt="Logo" />
          </LargeContainer>
          <ContentColumn>
            {" "}
            <Text
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={handleImageOpen}
              color="#1696ff"
            >
              {tokenData?.type === "education"
                ? "View Certificate"
                : "View Token"}
            </Text>
          </ContentColumn>

          <ContentColumn>
            <FieldFlex>
              <Text grid primary>
                Type :{" "}
              </Text>
              <SubHeading style={{ fontSize: "16px" }}>
                {tokenData.type === "education" ? "Certificate" : "Token"}
              </SubHeading>
            </FieldFlex>
            <FieldFlex>
              <Text grid primary>
                Blockchain :{" "}
              </Text>
              <SubHeading style={{ fontSize: "16px" }}>{blockchain}</SubHeading>
            </FieldFlex>
          </ContentColumn>
        </TopColumn>
        <Column>
          {!matches && (
            <MainContentColumn>
              <div>
                <LargeHeading primary>{tokenData?.itemName}</LargeHeading>
                <Text lighter>
                  Issued by&nbsp;
                  <Link href={`/profile/${tokenData?.senderPublicKey}`}>
                    {`${tokenData?.alias} `}
                  </Link>
                </Text>
              </div>
              <Text primary lighter>
                {tokenData?.description}
              </Text>
            </MainContentColumn>
          )}
          <SubContentColumn>
            <SubHeading primary>Meta Data</SubHeading>
            <GridContainer>
              {tokenData?.name &&

                <> <Text grid lighter primary>
                  Issued To
                </Text>
                  :
                  <Link
                    href={
                      tokenData?.profilePublicKey != undefined &&
                      `/profile/${tokenData?.profilePublicKey}`
                    }
                  >
                    {tokenData?.name}
                  </Link></>



              }
              {tokenData?.profilePublicKey != undefined && (
                <>
                  <Text grid lighter primary>
                    Public Key
                  </Text>
                  :
                  <Span
                    cursor
                    onClick={() => copyToClipBoard(tokenData?.profilePublicKey)}
                  >
                    {tokenData?.profilePublicKey}
                  </Span>
                </>
              )}
              <Text grid lighter primary>
                Token Id
              </Text>{" "}
              :<Span>{tokenData.tokenId}</Span>
              <Text grid lighter primary>
                Issued Date
              </Text>
              :<Span>{moment(tokenData?.date).format("LLL")}</Span>
              <Text grid lighter primary>
                TXN Hash
              </Text>
              :
              <Link
                target="_blank"
                href={`https://${urlName}.com/tx/${tokenData?.txnHashes?.claimedTxnHash}`}
              >
                {tokenData.txnHashes?.claimedTxnHash?.toString()}
              </Link>
            </GridContainer>
          </SubContentColumn>
        </Column>
      </FlexContainer>
    </Container>
  );
};

export default Content;

const ContentColumn = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    align-items: unset;
  }
`;

const MainContentColumn = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
`;

const SubContentColumn = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const FieldFlex = styled.div`
  display: flex;
  gap: 1rem;
`;

const Column = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  @media (max-width: 1368px) {
    /* align-items: center; */
  }
`;

const TopColumn = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  @media (max-width: 1368px) {
    align-items: center;
  }
`;

const Container = styled.div`
  padding: 3rem 10rem;
  display: flex;
  align-items: center;
  @media (max-width: 1568px) {
    padding: 3rem 1rem;
  }
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const Icon = styled.img`
  width: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
`;

const AbsoluteHover = styled.div`
  position: absolute;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 50%;
  left: 50%;
  background: linear-gradient(180deg, #29314963 0%, #1b223696 100%);
  z-index: 1;
  transform: translate(-50%, -50%);
  transition: all 200ms ease-in-out;
`;

const LargeContainer = styled.div`
  position: relative;
  min-width: 20rem;
  min-height: 20rem;
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  background: #dddddd;
  border-radius: 50%;
  &:hover {
    ${AbsoluteHover} {
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
    min-width: 15rem;
    min-height: 15rem;
    width: 10rem;
    height: 10rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 4rem;
  @media (max-width: 1368px) {
    gap: 3rem;
    padding: 2rem 0rem;
    flex-direction: column;
  }
`;

const Span = styled.span`
  font-weight: 500;
  max-width: 600px;
  word-break: break-word;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};

  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
    font-size: 14px;
  }
`;

const GridContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 0.01fr 4fr;
  @media (max-width: 768px) {
  }
`;
export const Image = styled.img`
  width: 4rem;
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  justify-self: end;
  border-radius: 5px;
  text-align: center;
`;

export const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;

export const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 60vh;
  overflow: hidden;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
