import React, { Fragment, useEffect, useState } from "react";
import { GetIPFSData, me } from "../../services/api.js";
import { urlToHash } from "../../services/ipfs";
import InnerLoadingView from "../shared/loading/InnerLoadingView.js";
import { SubHeading, Text } from "../shared/Typography/Typo.js";
import { Error, ErrorContainer } from "./Token.js";
import ErrorBG from "../../assets/images/not-found.gif";
import ProductProfile from "../shared/ProductProfile/ProductProfile.js";

const ProductPage = ({ publicKey }) => {
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [loading, setLoading] = useState(false);

  const [productData, setProductData] = useState({});

  const fetchLatestProfileData = async () => {
    setLoading(true);
    try {
      const meDataRes = await me({
        path: {
          id: publicKey,
        },
      });

      if (meDataRes && meDataRes?.data?.data?.length > 0) {
        const hash = urlToHash(
          meDataRes?.data?.data[0]?.latestIpfsUrl?.ipfsUrl
        );
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
        setProductData(meDataRes.data.data[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLatestProfileData();
  }, []);

  return (
    <Fragment>
      {(() => {
        if (loading) {
          return <InnerLoadingView style={{ marginTop: "15rem" }} />;
        } else if (productData.profileType === 3) {
          return <ProductProfile profileData={latestIpfsData} />;
        } else {
          return (
            <ErrorContainer style={{ marginTop: "5rem" }}>
              <Error bg={ErrorBG} width="500px">
                <SubHeading primary>{` Not Found!`}</SubHeading>
              </Error>
              <Text primary>Looks like you're lost</Text>
            </ErrorContainer>
          );
        }
      })()}
    </Fragment>
  );
};

export default ProductPage;
